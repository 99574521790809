import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Breadcrumb from '../../Layouts/Breadcrumb';
import Authapi from '../../Services/Authapi';
import swal from 'sweetalert';
import { CardContent, Card, Typography, Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import FontAwesomeIconComponent from '../../Layouts/FontAwesomeIconComponent';
import AuthCheckComponent from '../../Layouts/Token';

// Define custom styles
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    card: {
        backgroundColor: '#f5f5f5',
        marginBottom: theme.spacing(2),
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#1b6e95',
    },
    button: {
        backgroundColor: '#1b6e95',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#303f9f',
        },
    },
    iconButton: {
        color: '#1b6e95',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
}));

export default function Reviewformlist(props) {
    const classes = useStyles();
    const [data, setdata] = useState();

    useEffect(() => {
        Reviewformlist();
    }, []);

    const Reviewformlist = async () => {
        try {
            let role = await Authapi.Reviewformlist();
            setdata(role.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEdit = (id) => {
        props.history.push(`/Reviewformedit/${id}`);
    };

    const handledelete = async (id) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: ["Cancel", "Yes"]
        }).then(async (confirm) => {
            if (confirm) {
                let currentPermission = await Authapi.Reviewformdelete(id);
                if (currentPermission && currentPermission.status === true) {
                    swal("Success", "Form deleted successfully!", "success");
                    Reviewformlist();
                }
            }
        });
    };

    const hardik = () => {
        props.history.push('/Reviewformadd');
    };

    return (
        <div className={classes.root}>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                setAuthUser={props.setAuthUser}
                component={
                    <div>
                        <Card className={classes.card}>
                            <CardContent>
                                <Breadcrumb {...props}
                                    primaryPageName="Feedback Forms"
                                    primaryPageLink="/dashboard"
                                    isSecondaryPage={false}
                                    secondaryPageName="" />
                            </CardContent>
                        </Card>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <div className={classes.headerContainer}>
                                            <Typography className={classes.title}>All Forms</Typography>
                                            <Button
                                                style={{ backgroundColor: "#1B6E95" }}
                                                variant="contained"
                                                onClick={hardik}
                                                className={classes.button}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </CardContent>
                                    <Grid container spacing={3}>
                                        {data && data.map((item) => (
                                            <Grid item xs={4} key={item.id}>
                                                <Card className={classes.card}>
                                                    <CardContent>
                                                        <Typography variant="h5" component="div">
                                                            {item.form_name}
                                                        </Typography>
                                                        <Grid container spacing={1}>
                                                            <Grid item>
                                                                <Tooltip title=" Edit">
                                                                    <IconButton
                                                                        className={classes.iconButton}
                                                                        onClick={() => handleEdit(item.id)}
                                                                    >
                                                                        <FontAwesomeIconComponent
                                                                            classes="fa fa-edit"
                                                                            fontSize={"small"}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Delete">
                                                                    <IconButton
                                                                        className={classes.iconButton}
                                                                        onClick={() => handledelete(item.id)}
                                                                    >
                                                                        <FontAwesomeIconComponent
                                                                            classes="fa fa-trash"
                                                                            fontSize={"small"}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                }
            />
            <Footer {...props} />
        </div>
    );
}
