import config from "../../Config";
import axios from "axios";
import ls from "local-storage";
// import moment from 'moment-timezone';
// import jsPDF from "jspdf";
import swal from "sweetalert";

// eslint-disable-next-line import/no-anonymous-default-export
export default new (class AuthApi {
  setHeaders(type) {
    let authToken =
      ls.get("authToken") &&
        ls.get("authToken") !== null &&
        ls.get("authToken") !== false
        ? ls.get("authToken")
        : "";
    axios.defaults.headers[type]["Content-Type"] = "multipart/form-data";
    // axios.defaults.headers[type]['Content-Type'] = 'application/json;charset=utf-8';
    axios.defaults.headers[type]["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers[type]["Authorization"] = `Bearer ${authToken}`;
  }

  // async inTime() {
  //   try {
  //     const url = config.apiurl + config.apis.userInTime;
  //     let postData = { inTime: "intimedata" };
  //     this.setHeaders("post");
  //     let data = await axios
  //       .post(url, postData)
  //       .then((res) => {
  //         return res.data.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     // console.log(error);

  //     return false;
  //   }
  // }

  async inTime() {
    const url = config.apiurl + config.apis.userInTime;
    let postData = { inTime: "intimedata" };
    this.setHeaders("post");
    try {
      let response = await axios.post(url, postData);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async outTime() {
    try {
      const url = config.apiurl + config.apis.userOutTime;
      let postData = { outTime: "outtimedata" };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }



  async login(userData) {
    try {
      const url = config.apiurl + config.authApis.login;
      let data = await axios
        .post(url, userData)
        .then((res) => {
          return res.data.data;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data.message;
          } else {
            return "An error occurred, Please try again later!";
          }
        });
      return data;
    } catch (error) {
      return "An error occurred, Please try again later!";
    }
  }

  async refreshToken(authToken) {
    try {
      const url = `${config.apiurl}${config.authApis.refreshToken}`;
      const response = await axios.post(url, authToken);
      return response.data.data.original.data;
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
        return error.response.data.message;
      } else {
        console.error('Error:', error);
        return "An error occurred, Please try again later!";
      }
    }
  }

  // async gmailotp(userData) {
  //   try {
  //     const url = config.apiurl + config.authApis.gmailotp;
  //     let data = await axios
  //       .post(url, userData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }


  async gmailotp(userData) {
    try {
      const url = config.apiurl + config.authApis.gmailotp;
      const response = await axios.post(url, userData);
      return response.data;
    } catch (error) {
      console.log('Error occurred:', error);
      if (error.response) {
        return { status: false, message: error.response.data.message };
      } else {
        return { status: false, message: "An error occurred, Please try again later!" };
      }
    }
  }




  // async verifyotp(userData) {
  //   try {
  //     const url = config.apiurl + config.authApis.verifyotp;
  //     let data = await axios
  //       .post(url, userData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }


  async verifyotp(userData) {
    try {
      const url = config.apiurl + config.authApis.verifyotp;
      const response = await axios.post(url, userData);
      return response.data;
    } catch (error) {
      console.log('Error occurred:', error);
      if (error.response) {
        return { status: false, message: error.response.data.message };
      } else {
        return { status: false, message: "An error occurred, Please try again later!" };
      }
    }
  }



  // async resetpassword(userData) {
  //   try {
  //     const url = config.apiurl + config.authApis.resetpassword;
  //     let data = await axios
  //       .post(url, userData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }


  async resetpassword(userData) {
    try {
      const url = config.apiurl + config.authApis.resetpassword;
      const response = await axios.post(url, userData);
      return response.data;
    } catch (error) {
      console.log('Error occurred:', error);
      if (error.response) {
        return { status: false, message: error.response.data.message };
      } else {
        return { status: false, message: "An error occurred, Please try again later!" };
      }
    }
  }


  async getData() {
    try {
      const url = config.apiurl + config.apis.userList;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async logout() {
    try {
      const url = config.apiurl + config.authApis.logout;
      this.setHeaders("post");
      let data = await axios
        .post(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async checkAuth() {
    try {
      const url = config.apiurl + config.apis.authUser;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }




  // async refreshToken() {
  //   try {
  //     const url = config.apiurl + config.apis.refreshToken;
  //     this.setHeaders("get");
  //     let data = await axios
  //       .get(url)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  async getpermissions() {
    try {
      const url = config.apiurl + config.apis.getpermissions;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async createPermission(createPermission) {
    try {
      const url = config.apiurl + config.apis.createPermission;
      let postData = {
        permissionName: createPermission,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async updatePermission(createPermission, id) {
    try {
      const url = config.apiurl + config.apis.updatePermission + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        permissionName: createPermission,
      };
      let response = await axios.put(url, postData, axiosConfig);

      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async deletePermission(id) {
    try {
      const url = config.apiurl + config.apis.deletePermission + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Templatedelete(id) {
    try {
      const url = config.apiurl + config.apis.Templatedelete + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Salarydelete(id) {
    try {
      const url = config.apiurl + config.apis.Salarydelete + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async cronlogsdelete(id) {
    try {
      const url = config.apiurl + config.apis.cronlogsdelete + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async getRole() {
    try {
      const url = config.apiurl + config.apis.getRole;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async cronlogs() {
    try {
      const url = config.apiurl + config.apis.cronlogs;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async createRole(createRole, permissions) {
    try {
      const url = config.apiurl + config.apis.createRole;
      let postData = {
        roleName: createRole,
        permissionName: permissions,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async deleteRole(id) {
    try {
      const url = config.apiurl + config.apis.deleteRole + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async userDelete(id) {
    try {
      const url = config.apiurl + config.apis.deleteUser + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async updateRole(id, editValues) {
    try {
      const url = config.apiurl + config.apis.updateRole + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        // roleName: createRole,
        role_id: id,
        permission_id: editValues,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async updateImg(postData) {
    try {
      const url = config.apiurl + config.apis.updateImg;
      this.setHeaders("post", true);
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async deleteImg(image) {
    try {
      const url = config.apiurl + config.apis.deleteImg;
      this.setHeaders("post");
      let postData = {
        file: image,
      };
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async createUser(postData) {
    try {
      const url = config.apiurl + config.apis.createUser;
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async updateUser(user, id) {
    try {
      const url = config.apiurl + config.apis.editUser + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        photo: user.photo,
        gender: user.gender,
        dob: user.dob,
        qualification: user.qualification,
        last_organization: user.last_organization,
        salary: user.salary,
        mobileNo: user.mobileNo,
        bankName: user.bankName,
        bankAccNo: user.bankAccNo,
        doj: user.doj,
        location: user.location,
        department: user.department,
        designation: user.designation,
        roleName: user.roleName,
        password: user.password,
        allowed_ip: user.allowed_ip,
        designation_id: user.designation_id,
        reporting_person_id: user.reporting_person_id,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async singleUser(id) {
    try {
      const url = config.apiurl + config.apis.singleUser + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async durTime(userId) {
    try {
      const url = config.apiurl + config.apis.dureTime + userId;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async dureTime1(userId) {
    try {
      const url = config.apiurl + config.apis.dureTime1 + userId;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async createHoliday(postDatas) {
    try {
      const url = config.apiurl + config.apis.holidayLeave;
      const user = ls("user");
      const userName = `${user.firstName} ${user.lastName}`;
  
      const postData = {
        leaveName: postDatas.leaveName,
        date: postDatas.date,
        userName: userName,
      };
      this.setHeaders("post");
  
      const response = await axios.post(url, postData);
      return response.data;
    } catch (error) {
      console.error("Error in createHoliday:", error);
      if (error.response && error.response.data) {
        return error.response.data;
      }
      return { status: false, message: "An unexpected error occurred." };
    }
  }

  // async createHoliday(postDatas) {
  //   try {
  //     let url = config.apiurl + config.apis.holidayLeave;

  //     // Extract relevant properties from postDatas
  //     let { leaveName, dateRange, noOfDays } = postDatas;

  //     // Create postData object
  //     let postData = {
  //       leaveName,
  //       dateRange,
  //       noOfDays,
  //     };

  //     // Set headers using a separate function (assuming setHeaders is defined elsewhere)
  //     this.setHeaders("post");

  //     // Use async/await directly instead of mixing with .then() and .catch()
  //     let response = await axios.post(url, postData);

  //     // Assuming you want to return the data from the response
  //     return response.data;
  //   } catch (error) {
  //     // Log or handle the error as needed
  //     console.error("Error in createHoliday:", error);

  //     // Return false or handle the error response accordingly
  //     return false;
  //   }
  // }






  // async Templatepostdata(postDatas) {
  //   try {
  //     const url = config.apiurl + config.apis.Templatepostdata;
  //     let postData = {
  //       title: postDatas.title,
  //       letters: postDatas.texteditor,
  //     };
  //     this.setHeaders("post");
  //     let data = await axios
  //       .post(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }


  async Templatepostdata(postDatas) {
    try {
      const url = config.apiurl + config.apis.Templatepostdata;
      let postData = {
        title: postDatas.title,
        letters: postDatas.texteditor,
        templates: postDatas.templates
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async Reviewformadd(postDatas) {
    console.log(postDatas)
    try {
      const url = config.apiurl + config.apis.Reviewformadd;
      // let postData = {
      //   form_name: postDatas.form_name,
      //   form_details: postDatas.form_details,
      //   form_content: postDatas.form_content
      // };
      this.setHeaders("post");
      let data = await axios
        .post(url, postDatas)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Reviewformupdate(postDatas, id) {
    try {
      const url = config.apiurl + config.apis.Reviewformupdate + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let response = await axios.put(url, postDatas, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }



  async Reviewformdelete(id) {
    try {
      const url = config.apiurl + config.apis.Reviewformdelete + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Reviewformdynamicdelete(id) {
    try {
      const url = config.apiurl + config.apis.Reviewformdynamicdelete + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }




  async Reviewformlist() {
    try {
      const url = config.apiurl + config.apis.Reviewformlist;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }
  async ReviewformlistgetformbyID(id) {
    try {
      const url = config.apiurl + config.apis.ReviewformlistgetformbyID + id;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async Reviewformdynamicadd(postDatas, formtitle) {
    try {
      const url = config.apiurl + config.apis.Reviewformdynamicadd + formtitle;
      // let postData = {
      //   form_name: postDatas.form_name,
      //   form_details: postDatas.form_details,
      //   form_content: postDatas.form_content
      // };
      this.setHeaders("post");
      let data = await axios
        .post(url, postDatas)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }




async Reviewformdynamiclistid(id) {
    try {
      const url = config.apiurl + config.apis.Reviewformdynamiclistid + id;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Reviewformdynamiclist() {
    try {
      const url = config.apiurl + config.apis.Reviewformdynamiclist ;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }































  async Templategetdata() {
    try {
      const url = config.apiurl + config.apis.Templategetdata;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Relievingletterget() {
    try {
      const url = config.apiurl + config.apis.Relievingletterget;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }






  async alllettersget(templateName) {
    try {
      const url = `${config.apiurl}${config.apis.alllettersget}${templateName}`;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }







  //   async addlettersFieldata(postDatas, templateName) {
  //     console.log("Data being sent to API:", postDatas); // Debugging line
  //     try {
  //         const url = `${config.apiurl}${config.apis.addlettersFieldata}${templateName}`;

  //         // Send the postDatas directly without wrapping it in another object
  //         let data = await axios
  //             .post(url, postDatas) // Send postDatas directly
  //             .then((res) => {
  //                 return res.data;
  //             })
  //             .catch((error) => {
  //                 console.error("Error in API call:", error.response.data); // Log the error response for debugging
  //                 return false;
  //             });
  //         return data;
  //     } catch (error) {
  //         console.error("Unexpected error:", error); // Log unexpected errors
  //         return false;
  //     }
  // }




  // async addlettersFieldata(postDatas, templateName) {
  //   // console.log(postDatas)
  //   try {
  //       const url = `${config.apiurl}${config.apis.addlettersFieldata}${templateName}`;
  //       const user = ls("authToken");
  //       const headers = {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${user}` 
  //       };
  //       let data = await axios
  //           .post(url, postDatas, { headers })
  //           .then((res) => {
  //               return res.data;
  //           })
  //           .catch((error) => {
  //               console.error("Error in API call:", error.response.data); 
  //               return false;
  //           });
  //       return data;
  //   } catch (error) {
  //       console.error("Unexpected error:", error); 
  //       return false;
  //   }
  // }



  async addlettersFieldata(postDatas, templateName) {
    try {
      const url = `${config.apiurl}${config.apis.addlettersFieldata}${templateName}`;
      const user = ls("authToken");
      const headers = {
        'Authorization': `Bearer ${user}`
      };

      let data = await axios
        .post(url, postDatas, { headers })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error in API call:", error.response.data);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Unexpected error:", error);
      return false;
    }
  }



  salarydetailsdownload = async (month, year) => {
    try {
      const url = `${config.apiurl}${config.apis.salarydetailsdownload}${month}/${year}`;
      this.setHeaders("get");
      const response = await axios.get(url, {
        responseType: 'blob',
      });
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', `salary_details_${month}_${year}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading salary details:", error);
      swal({
        title: "Error",
        text: "Failed to download salary details. Please try again later.",
        icon: "error",
      });
    }
  }



  async dynamicletterview(id, type) {
    try {
      const url = `${config.apiurl}${config.apis.dynamicletterview}/${id}/${type}`;
      let postData = {
        responseType: "blob",
      };
      this.setHeaders("get");
      let data = await axios
        .get(url, postData)
        .then((res) => {
          const user = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          return user;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async dynamicletterdownload(id, type) {
    try {
      const url = `${config.apiurl}${config.apis.dynamicletterdownload}/${id}/${type}`;
      let postData = {
        responseType: "blob",
      };
      this.setHeaders("get");
      let data = await axios
        .get(url, postData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${type}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async dynamicletterdelete(id) {
    try {
      const url = config.apiurl + config.apis.dynamicletterdelete + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Addsalaryall() {
    try {
      const url = config.apiurl + config.apis.Addsalaryall;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data.message;
          } else {
            return "An error occurred, Please try again later!";
          }
        });
      return data;
    } catch (error) {
      return false;
    }
  }
  // async createTicket(postDatas) {
  //   try {
  //     const url = config.apiurl + config.apis.createTicket;
  //     let postData = {
  //       leaveType: postDatas.leaveType,
  //       ticketMesasge: postDatas.ticketMesasge,
  //       dateRange: postDatas.dateRange,
  //       noOfDays: postDatas.noOfDays,
  //       firstleavetype: postDatas.firstleavetype,
  //       secondleavetype: postDatas.secondleavetype,
  //     };

  //     this.setHeaders("post");
  //     let data = await axios
  //       .post(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           return error.response.data.message;
  //         } else {
  //           return "An error occurred, Please try again later!";
  //         }
  //       });
  //     return data;
  //   } catch (error) {
  //     return "An error occurred, Please try again later!";
  //   }
  // }






  async calculateworking() {
    try {
      const url = config.apiurl + config.apis.calculateworking;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  // async Templatepostdata(postDatas) {
  //   try {
  //     const url = config.apiurl + config.apis.Templatepostdata;
  //     let postData = {
  //       leaveName: postDatas.leaveName,
  //       dateRange: postDatas.dateRange,
  //       noOfDays: postDatas.noOfDays,
  //     };
  //     this.setHeaders("post");
  //     let data = await axios
  //       .post(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  async offerletteradd(postDatas) {
    try {
      const url = config.apiurl + config.apis.offerletteradd;
      let postData = {
        candidate_name: postDatas.candidateName,
        designation: postDatas.designation,
        date_of_join: postDatas.doj,
        monthly_salary: postDatas.monthlySalary,
        location: postDatas.location,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Relievingletterpost(postDatas) {
    try {
      const url = config.apiurl + config.apis.Relievingletterpost;
      let postData = {
        firstName: postDatas.firstName,
        doj: postDatas.dojdate,
        mobileNo: postDatas.mobileNo,
        candidate_name: postDatas.candidateName,
        designation: postDatas.designation,
        end_date: postDatas.doj,
        // monthly_salary: postDatas.monthlySalary,
        location: postDatas.location,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async getLeaves() {
    try {
      const url = config.apiurl + config.apis.holidayLeave;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async userleave() {
    try {
      const url = config.apiurl + config.apis.userleave;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async leaveDelete(id) {
    try {
      const url = config.apiurl + config.apis.removeLeave + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Relievingletterdelete(id) {
    try {
      const url = config.apiurl + config.apis.Relievingletterdelete + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async updateLeave(leave, id) {
    try {
      const url = config.apiurl + config.apis.eidtLeave + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        name: leave.leaveName,
        date: leave.date,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error in updateLeave:", error);
      if (error.response && error.response.data) {
        return error.response.data;
      }
      return { status: false, message: "An unexpected error occurred." };
    }
  }

  async Templategeedit(id) {
    try {
      const url = config.apiurl + config.apis.Templategeedit + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async singleLeave(id) {
    try {
      const url = config.apiurl + config.apis.singleLeave + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Templategetstatu(id, status) {
    try {
      const url = config.apiurl + config.apis.Templategetstatu;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        id: id,
        status: status,
      };
      let data = await axios
        .put(url, postData, axiosConfig)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data.message;
          } else {
            return "An error occurred, Please try again later!";
          }
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async createTicket(postDatas) {
    try {
      const url = config.apiurl + config.apis.createTicket;
      let postData = {
        leaveType: postDatas.leaveType,
        ticketMesasge: postDatas.ticketMesasge,
        dateRange: postDatas.dateRange,
        // noOfDays: postDatas.noOfDays,
        firstleavetype: postDatas.firstleavetype,
        secondleavetype: postDatas.secondleavetype,
      };

      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data.message;
          } else {
            return "An error occurred, Please try again later!";
          }
        });
      return data;
    } catch (error) {
      return "An error occurred, Please try again later!";
    }
  }



  async getTickets() {
    try {
      const url = config.apiurl + config.apis.createTicket;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async userimg() {
    try {
      const url = config.apiurl + config.apis.userimg;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async userimgid(id) {
    try {
      const url = config.apiurl + config.apis.userimgid + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  // async updateTicket(ticket, id) {
  //   try {
  //     const url = config.apiurl + config.apis.EditTicket + id;
  //     this.setHeaders("put");
  //     let postData = {
  //       leaveStatus: ticket.leaveStatus,
  //     };
  //     let data = await axios
  //       .put(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  async updateTicket(ticket, id) {
    try {
      const url = config.apiurl + config.apis.EditTickets + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      const postData = {
        leaveType: ticket.leaveType,
        date_range: ticket.dateRange,
        firstleavetype: ticket.firstleavetype,
        secondleavetype: ticket.secondleavetype,
        ticketMesasge: ticket.ticketMesasge,
        // no_of_days: ticket.noOfDays,
      };

      if (ls("roles") === "Employee" && ticket.leaveStatus === "Pending") {
        const response = await axios.put(url, postData, axiosConfig);
        return response.data;
      }
    } catch (error) {
      console.error("Error:", error);
      return { status: false, message: error.response?.data?.message || "An unexpected error occurred." };
    }
  }

  async singleTicket(id) {
    try {
      const url = config.apiurl + config.apis.singleTicket + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async getAttandance(month, year) {
    try {
      const url =
        config.apiurl + config.apis.getAttandance + month + "/" + year;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async AllgetAttandance(month, year) {
    try {
      const url =
        config.apiurl + config.apis.AllgetAttandance + month + "/" + year;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async getEvent(month, year) {
    try {
      const url = config.apiurl + config.apis.getEvent + month + "/" + year;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async createEvent(postDatas, postImages) {
    try {
      const url = config.apiurl + config.apis.createEvent;
      let postData = {
        name: postDatas.eventName,
        date: postDatas.eventdate,
        images: postImages,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async eventDelete(id) {
    try {
      const url = config.apiurl + config.apis.DeleteEvent + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async singleEvent(id) {
    try {
      const url = config.apiurl + config.apis.singleEvent + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async editRole(id) {
    try {
      const url = config.apiurl + config.apis.editRole + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async updateEvent(event, id, postImages) {
    try {
      const url = config.apiurl + config.apis.editEvent + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        name: event.eventName,
        date: event.eventdate,
        images: postImages,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async imageRemove(id, key) {
    try {
      const url = config.apiurl + config.apis.removeImage + id + "/" + key;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async createUpcomingEvent(postDatas) {
    try {
      const url = config.apiurl + config.apis.createUpcomingEvent;
      let postData = {
        name: postDatas.upcomingEventName,
        date: postDatas.upcomingEventdate,
        description: postDatas.description,
        image: postDatas.image,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async uploadEventImg(postData) {
    try {
      const url = config.apiurl + config.apis.uploadEventImg;
      this.setHeaders("post", true);
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async getAllEvents() {
    try {
      const url = config.apiurl + config.apis.getAllEvent;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async getAttendance(id, month, year) {
    try {
      const url = `${config.apiurl}${config.apis.getAttendance}${id}/${month}/${year}`;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async getdailylog(id, day, month, year) {
    try {
      const url = `${config.apiurl}${config.apis.getdailylog}${id}/${day}/${month}/${year}`;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async getalldatadailylog(day, month, year) {
    try {
      const url = `${config.apiurl}${config.apis.getalldatadailylog}${day}/${month}/${year}`;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async getallAttendance(month, year) {
    try {
      const url = `${config.apiurl}${config.apis.getallAttendance}${month}/${year}`;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async offerletterview(id) {
    try {
      const url = `${config.apiurl}${config.apis.offerletterview}${id}`;
      let postData = {
        responseType: "blob",
      };
      this.setHeaders("get");
      let data = await axios
        .get(url, postData)

        .then((res) => {
          const user = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          return user;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Relievingletterview(id) {
    try {
      const url = `${config.apiurl}${config.apis.Relievingletterview}${id}`;
      let postData = {
        responseType: "blob",
      };
      this.setHeaders("get");
      let data = await axios
        .get(url, postData)

        .then((res) => {
          const user = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          return user;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async offerletterdo(offerId) {
    try {
      const url = `${config.apiurl}${config.apis.offerletterdo}${offerId}`;
      let postData = {
        responseType: "blob",
      };
      this.setHeaders("get");
      let data = await axios
        .get(url, postData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "offer_letter.pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  // async offerletterdo(id) {
  //   try {
  //     const url = config.apiurl + config.apis.offerletterdo + id;
  //     let postData = {
  //       responseType: "blob",
  //     };
  //     this.setHeaders("get");
  //     let data = await axios
  //       .get(url, postData)

  //       .then((response) => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", "offer_letter.pdf"); //or any other extension
  //         document.body.appendChild(link);
  //         link.click();
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  async Relievingletterdo(id) {
    try {
      const url = `${config.apiurl}${config.apis.Relievingletterdo}${id}`;
      let postData = {
        responseType: "blob",
      };
      this.setHeaders("get");
      let data = await axios
        .get(url, postData)

        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Relieving_letter.pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async AlleventDelete(id) {
    try {
      const url = config.apiurl + config.apis.AllDeleteEvent + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async offerletterdelete(id) {
    try {
      const url = config.apiurl + config.apis.offerletterdelete + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async singleUpcomingEvent(id) {
    try {
      const url = config.apiurl + config.apis.singleUpcomingEvent + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async updateUpcomingEvent(postDatas, id) {
    try {
      const url = config.apiurl + config.apis.EditupcomingEvent + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        name: postDatas.upcomingEventName,
        date: postDatas.upcomingEventdate,
        description: postDatas.description,
        image: postDatas.image,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  // async Templateupdate(postDatas, id) {
  //   try {
  //     const url = config.apiurl + config.apis.Templateupdate + id;
  //     const headers = {
  //       "Content-Type": "application/json",
  //     };
  //     let axiosConfig = {
  //       headers: headers,
  //     };
  //     let postData = {
  //       template_name: postDatas.title,
  //       letters: postDatas.texteditor,
  //     };
  //     let response = await axios.put(url, postData, axiosConfig);
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error:", error);
  //     return false;
  //   }
  // }


  async Templateupdate(postDatas, id) {
    try {
      const url = config.apiurl + config.apis.Templateupdate + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        template_name: postDatas.title,
        letters: postDatas.texteditor,
        templates: postDatas.templates,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }




  async upcomingdeleteImg(image) {
    try {
      const url = config.apiurl + config.apis.deleteUpcomingImage;
      this.setHeaders("post");
      let postData = {
        file: image,
      };
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  // async addSalary(event, id) {
  //   try {
  //     const url = config.apiurl + config.apis.addSalary + id;
  //     this.setHeaders("put");
  //     let postData = {
  //       salary: event.salary,
  //     };
  //     let data = await axios
  //       .put(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }
  async addSalary(event, id) {
    try {
      const url = config.apiurl + config.apis.addSalary + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        salary: event.salary,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }





  async getAllSalaryData(month, year, id) {
    try {
      const url =
        config.apiurl + config.apis.AllSalarydata + month + "/" + year + "/" + id;

      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  // async getAllSalaryPdf(month, year) {
  //   try {
  //     const url = config.apiurl + config.apis.All + month + "/" + year;

  //     this.setHeaders("get");
  //     let data = await axios
  //       .get(url)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  async getAllSalaryPdf(id) {
    try {
      const url = `${config.apiurl}${config.apis.AllSalaryPdf}/${id}`;
      let postData = {
        responseType: "blob",
      };
      this.setHeaders("get");
      let data = await axios
        .get(url, postData)
        .then((res) => {
          const data = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = data;
          link.setAttribute("download", "Salary_Slip.pdf");
          document.body.appendChild(link);
          link.click();
          return res.data;
        })
        .catch((error) => {
          console.error("Error downloading salary slip:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error downloading salary slip:", error);
      return false;
    }
  }

  // async alldatasalary() {
  //   try {
  //     const url = config.apiurl + config.apis.alldatasalary;
  //     this.setHeaders("get");
  //     let data = await axios.get(url).then((res) => {
  //       return res.data;
  //     });
  //     console.log(data, "data").catch((error) => {
  //       return false;
  //     });
  //     return data;
  //     // console.log
  //   } catch (error) {
  //     return false;
  //   }
  // }

  async alldatasalary() {
    try {
      const url = config.apiurl + config.apis.alldatasalary;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async alldatasalaryid(month, year, id) {
    try {
      const formattedMonth = month.toString().padStart(2, '0');
      const url = `${config.apiurl}${config.apis.alldatasalaryid}${formattedMonth}/${year}/${id}`;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async viewSalarySlip(id) {
    try {
      const url = `${config.apiurl}${config.apis.AllSalaryview}/${id}`;
      let postData = {
        responseType: "blob",
      };
      this.setHeaders("get");
      let data = await axios
        .get(url, postData)
        .then((res) => {
          const user = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          return user;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async createjob(postDatas) {
    try {
      const url = config.apiurl + config.apis.createjob;

      let postData = {
        jobTitle: postDatas.jobtitle,
        numberOfVacancies: postDatas.numofvacanciese,
        expRequired: postDatas.exprequire,
        minQuallification: postDatas.minqualification,
        jobActive: postDatas.jobactive,
        jobDescription: postDatas.jobdescription,
        companyProfile: postDatas.Companyprofile,
        rolesResponbilities: postDatas.roleresponsibilities,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async getAllJob() {
    try {
      const url = config.apiurl + config.apis.getAllJob;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async deletejob(id) {
    try {
      const url = config.apiurl + config.apis.deletejob + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async singleJob(id) {
    try {
      const url = config.apiurl + config.apis.singlejob + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async updateJob(job, id) {
    try {
      const url = config.apiurl + config.apis.editjob + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        jobTitle: job.jobtitle,
        numberOfVacancies: job.numofvacanciese,
        expRequired: job.exprequire,
        minQuallification: job.minqualification,
        jobActive: job.jobactive,
        jobDescription: job.jobdescription,
        companyProfile: job.Companyprofile,
        rolesResponbilities: job.roleresponsibilities,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async getQulification() {
    try {
      const url = config.apiurl + config.apis.getQulification;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async createQulification(postDatas) {
    try {
      const url = config.apiurl + config.apis.createQulification;

      let postData = {
        qualification: postDatas.qulification,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }






  async Departmentcreate(postDatas) {
    try {
      const url = config.apiurl + config.apis.designationcreate;

      let postData = {
        designation: postDatas.department_name,
        paid_leave_allowed: postDatas.paid_leave_allowed,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Departmentshow() {
    try {
      const url = config.apiurl + config.apis.designationhow;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Departmentdelete(id) {
    try {
      const url = config.apiurl + config.apis.designationdelete + id;
      this.setHeaders("get");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Departmentshowbyid(id) {
    try {
      const url = config.apiurl + config.apis.Designationhowbyid + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Departmentupdate(qulif, id) {
    try {
      const url = config.apiurl + config.apis.designationupdate + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        designation: qulif.department_name,
        paid_leave_allowed: qulif.paid_leave_allowed,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }









  async Departmentcreate1(postDatas) {
    try {
      const url = config.apiurl + config.apis.Departmentcreate;

      let postData = {
        department: postDatas.department,
        // paid_leave_allowed: postDatas.paid_leave_allowed,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Departmentshow1() {
    try {
      const url = config.apiurl + config.apis.Departmentshow;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Departmentdelete1(id) {
    try {
      const url = config.apiurl + config.apis.Departmentdelete + id;
      this.setHeaders("get");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Departmentshowbyid1(id) {
    try {
      const url = config.apiurl + config.apis.Departmenthowbyid + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async Departmentupdate1(qulif, id) {
    try {
      const url = config.apiurl + config.apis.Departmentupdate + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        department: qulif.department,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }








































  async deleteQulification(id) {
    try {
      const url = config.apiurl + config.apis.deleteQulification + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async singleQulification(id) {
    try {
      const url = config.apiurl + config.apis.singleQulification + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async editQulification(qulif, id) {
    try {
      const url = config.apiurl + config.apis.editQulification + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        qualification: qulif.qulification,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  // async editQulification(qulif, id) {
  //   try {
  //     const url = config.apiurl + config.apis.editQulification + id;
  //     this.setHeaders("put");
  //     let postData = {
  //       qualification: qulif.qulification,
  //     };
  //     let data = await axios
  //       .put(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  async getExperience() {
    try {
      const url = config.apiurl + config.apis.getExperience;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async createExperience(postDatas) {
    try {
      const url = config.apiurl + config.apis.createExperience;
      let postData = {
        minExpYear: postDatas.minExpYear,
        maxExpYear: postDatas.maxExpYear,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async deleteExperience(id) {
    try {
      const url = config.apiurl + config.apis.deleteExperience + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async singleExp(id) {
    try {
      const url = config.apiurl + config.apis.singleExp + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async editExperience(exp, id) {
    try {
      const url = config.apiurl + config.apis.editExperience + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        minExpYear: exp.minExpYear,
        maxExpYear: exp.maxExpYear,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async bdayNotification() {
    try {
      const url = config.apiurl + config.apis.bdayNotification;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  // async addTime(postDatas) {
  //   try {
  //     const url = config.apiurl + config.apis.addTime +postDatas.id;
  //     let postData = {
  //       date: postDatas.selectedDate,
  //       in_time: postDatas.startTime,
  //       out_time: postDatas.endTime,
  //     };
  //     this.setHeaders("post");
  //     let data = await axios
  //       .post(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }


  async createTidcket(postDatas) {
    try {
      const url = config.apiurl + config.apis.createTicket;
      let postData = {
        leaveType: postDatas.leaveType,
        ticketMesasge: postDatas.ticketMesasge,
        dateRange: postDatas.dateRange,
        noOfDays: postDatas.noOfDays,
        firstleavetype: postDatas.firstleavetype,
        secondleavetype: postDatas.secondleavetype,
      };
      this.setHeaders("post");
      let data = await axios
        .post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data.message;
          } else {
            return "An error occurred, Please try again later!";
          }
        });
      return data;
    } catch (error) {
      return "An error occurred, Please try again later!";
    }
  }


  async addTime(postDatas) {
    try {
      const url = `${config.apiurl}${config.apis.addTime}${postDatas.id}`;
      let postData = {
        date: postDatas.selectedDate,
        in_time: postDatas.startTime,
        out_time: postDatas.endTime,
      };
      this.setHeaders("post");
      let data = await axios.post(url, postData)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data.message;
          } else {
            return "An error occurred, Please try again later!";
          }
        });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async chekUserData() {
    try {
      const url = config.apiurl + config.apis.chekUserData;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async deletetimeentry(id) {
    try {
      const url = config.apiurl + config.apis.deletetimeentry + id;
      this.setHeaders("delete");
      let data = await axios
        .delete(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async TimeEntrshowuser(id) {
    try {
      const url = config.apiurl + config.apis.TimeEntrshowuser + id;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async approveTime(postdatas, id) {
    try {
      const url = config.apiurl + config.apis.approveTime + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        is_approved: postdatas.is_approved,
        userName: postdatas.userName,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async UserStatus(id, status) {
    try {
      const url = config.apiurl + config.apis.userstatus;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let postData = {
        userid: id,
        status: status,
      };
      let response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  async Notificationread(id) {
    try {
      const url = config.apiurl + config.apis.Notificationread + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };

      let response = await axios.post(url, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }





  async Notificationview(id) {
    try {
      const url = config.apiurl + config.apis.Notificationview + id;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };

      let response = await axios.post(url, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }
















  async getTickets1() {
    try {
      const url = config.apiurl + config.apis.getleaves;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async getEmpLeaveticket() {
    try {
      const url = config.apiurl + config.apis.getEmpleaves;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async offerletter() {
    try {
      const url = config.apiurl + config.apis.offerletter;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }



  async Notification() {
    try {
      const url = config.apiurl + config.apis.Notification;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async NotificationEmployee() {
    try {
      const url = config.apiurl + config.apis.NotificationEmployee;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async relivingStatus(id, action) {
    try {
      const url = `${config.apiurl}${config.apis.relivingletter}/${id}`;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let status = "";
      if (action === "accepted") {
        status = "accepted";
      } else if (action === "rejected") {
        status = "rejected";
      } else {
        throw new Error("Invalid action");
      }
      let postData = {
        status: status,
      };
      const response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error updating reliving letter status:", error.message);
      throw error;
    }
  }

  // async approveTime(postdatas, id) {
  //   try {
  //     const url = config.apiurl + config.apis.approveTime + id;
  //     this.setHeaders("put");
  //     let postData = {
  //       is_approved: postdatas,
  //     };
  //     let data = await axios
  //       .put(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  // async searchAttandance(postData, durMonth, year) {
  //   try {
  //     const url =
  //       config.apiurl + config.apis.getAttandance + durMonth + "/" + year;

  //     this.setHeaders("post");
  //     let postData = {
  //       search: postData.value,
  //     };
  //     let data = await axios
  //       .post(url, postData)
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }

  // async updateTicketLeaveStatus(ticket, id) {
  //   try {
  //     const url = `${config.apiurl}${config.apis.EditTicket}${id}`;
  //     console.log("URL:", url);

  //     const headers = {
  //       "Content-Type": "application/json",
  //     };

  //     const axiosConfig = {
  //       headers: headers,
  //     };
  //     // console.log(axiosConfig);
  //     let postData = {
  //       leaveStatus: ticket.leaveStatus,
  //       cancel_reason: ticket.cancel_reason,
  //       c_reason: ticket.c_reason,
  //       userName: ticket.updated_by
  //     };

  //     if (ls("roles") === "Super Admin" && ticket.leaveStatus === "declined") {
  //       postData = {
  //         ...postData,
  //         cancel_reason: ticket.cancel_reason,
  //       };
  //     }
  //      if (ticket.leaveStatus === "cancelled") {
  //       postData = {
  //         ...postData,
  //         c_reason: ticket.c_reason,
  //       };
  //     }

  //     console.log("Sending postData:", postData);

  //     const response = await axios.put(url, postData, axiosConfig);
  //     console.log("Response Data:", response.data); 
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error updating ticket:", error);
  //     throw error;
  //   }
  // }


  async updateTicketLeaveStatus(ticket, id) {
    try {
      const url = `${config.apiurl}${config.apis.EditTicket}${id}`;
      const headers = {
        "Content-Type": "application/json",
      };
      const axiosConfig = {
        headers: headers,
      };
      let postData = {
        leaveStatus: ticket.leaveStatus,
        cancel_reason: ticket.cancel_reason,
        c_reason: ticket.c_reason,
        userName: ticket.updated_by
      };

      if (ls("roles") === "Super Admin" && ticket.leaveStatus === "declined") {
        postData = {
          ...postData,
          cancel_reason: ticket.cancel_reason,
        };
      }
      if (ticket.leaveStatus === "cancelled") {
        postData = {
          ...postData,
          c_reason: ticket.c_reason,
        };
      }
      const response = await axios.put(url, postData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error updating ticket:", error);
      throw error;
    }
  }


  async editTime(userId, timeEntryId, timeData) {
    try {
      const url = `${config.apiurl}${config.apis.Updartetimeentry}${userId}/${timeEntryId}`;
      const headers = {
        "Content-Type": "application/json",
      };
      let axiosConfig = {
        headers: headers,
      };
      let response = await axios.put(url, timeData, axiosConfig);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        return error.response.data.message;
      } else {
        return "An error occurred, Please try again later!";
      }
    }
  }

  async getPermission() {
    try {
      const url = config.apiurl + config.apis.getPermission;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async getNotificationIcons() {
    try {
      const url = config.apiurl + config.apis.getnotificationicons;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error fetching notification icons:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error fetching notification icons:", error);
      return false;
    }
  }

  async saveNotificationIcons(icons) {
    try {
      const url = config.apiurl + config.apis.savenotificationicons;
      this.setHeaders("post");
      let data = await axios
        .post(url, icons)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error saving notification icons:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error saving notification icons:", error);
      return false;
    }
  }

  async saveWorkingHours(hours) {
    try {
      const url = config.apiurl + config.apis.saveWorkingHours;
      this.setHeaders("post");
      let data = await axios
        .post(url, hours)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error saving working hours:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error saving working hours:", error);
      return false;
    }
  }

  async getWorkingHours() {
    try {
      const url = config.apiurl + config.apis.getWorkingHours;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error fetching working hours:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error fetching working hours:", error);
      return false;
    }
  }

  async saveleavebalance(leavebalance) {
    try {
      const url = config.apiurl + config.apis.saveleavebalance;
      this.setHeaders("post");
      let data = await axios
        .post(url, leavebalance)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error saving leave balance:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error saving leave balance:", error);
      return false;
    }
  }




  async employeenotifiwise(type) {
    try {
      const url = config.apiurl + config.apis.employeenotifiwise + type;
      this.setHeaders("post");
      let data = await axios
        .post(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error employee notifi wise:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error employee notifi wise:", error);
      return false;
    }
  }

  async adminnotifiwise(type) {
    try {
      const url = config.apiurl + config.apis.adminnotifiwise + type;
      this.setHeaders("post");
      let data = await axios
        .post(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error admin notifi wise:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error admin notifi wise:", error);
      return false;
    }
  }


  async notificationsallemployee() {
    try {
      const url = config.apiurl + config.apis.notificationsallemployee;
      this.setHeaders("post");
      let data = await axios
        .post(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error admin notifi wise:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error admin notifi wise:", error);
      return false;
    }
  }

  async notificationsalladmin() {
    try {
      const url = config.apiurl + config.apis.notificationsalladmin;
      this.setHeaders("post");
      let data = await axios
        .post(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error admin notifi wise:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error admin notifi wise:", error);
      return false;
    }
  }




















  async getleavebalance() {
    try {
      const url = config.apiurl + config.apis.getleavebalance;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error fetching leave balance:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error fetching leave balance:", error);
      return false;
    }
  }



  async userreportAttendance(userId, timeRange) {
    try {
      const url = config.apiurl + config.apis.userreportAttendance + userId + "/" + timeRange;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error fetching leave balance:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error fetching leave balance:", error);
      return false;
    }
  }

  async getUserReportByDateRange(userId, startDay, startMonth, startYear, endDay, endMonth, endYear) {
    try {
      const url = `${config.apiurl}${config.apis.getUserReportByDateRange}${userId}/${startDay}/${startMonth}/${startYear}/${endDay}/${endMonth}/${endYear}`;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error fetching user report by date range:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error fetching user report by date range:", error);
      return false;
    }
  }

  async leavetickets(userId, month, year) {
    try {
      const url = config.apiurl + config.apis.leavetickets + userId + "/" + month + "/" + year;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error fetching leave balance:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error fetching leave balance:", error);
      return false;
    }
  }



  async Readedemployeenotifications() {
    try {
      const url = config.apiurl + config.apis.Readedemployeenotifications;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async Readedadminnotifications() {
    try {
      const url = config.apiurl + config.apis.Readedadminnotifications;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }


  async ShowMissingTimeEntriesById(id, day, month, year) {
    try {
      const url = config.apiurl + config.apis.ShowMissingTimeEntriesById + id + "/" + day + "/" + month + "/" + year;
      this.setHeaders("get");
      let data = await axios
        .get(url)
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return false;
        });
      return data;
    } catch (error) {
      return false;
    }
  }

  async saveSectionsOrder(sections) {
    try {
      const url = config.apiurl + config.apis.savesections;
      const token = localStorage.getItem('authToken');
      let data = await axios
        .post(url, sections, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error saving sections order:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error saving sections order:", error);
      return false;
    }
  }

  async getSectionsOrder(userId) {
    try {
      const url = `${config.apiurl}${config.apis.getsections}?user_id=${userId}`;
      const token = localStorage.getItem('authToken');
      let data = await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.error("Error fetching sections order:", error);
          return false;
        });
      return data;
    } catch (error) {
      console.error("Error fetching sections order:", error);
      return false;
    }
  }



  // async fetchUserRole() {
  //   try {
  //     const url = config.apiurl + config.apis.fetchUserRole;
  //     const token = localStorage.getItem('authToken');
  //     this.setHeaders("get");
  //     let data = await axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //         },
  //       })
  //       .then((res) => {
  //         return res.data;
  //       })
  //       .catch((error) => {
  //         return false;
  //       });
  //     return data;
  //   } catch (error) {
  //     return false;
  //   }
  // }




  async toggleRole(id) {
    try {
      const url = `${config.apiurl}${config.apis.toggleRole}${id}`;
      const token = localStorage.getItem('authToken');
      const response = await axios.post(url, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error toggling role:", error);
      return false;
    }
  }





})();
