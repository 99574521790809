import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AuthApi from '../../Services/Authapi';
import swal from 'sweetalert';
import AuthCheckComponent from '../../Layouts/Token';


const inivalue = {
    department_name: "",
    paid_leave_allowed: ""
};

const Adddesignation = (props) => {
    const classes = props;
    const [qulifi, setQulifi] = useState(inivalue);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props && props.authToken === false) {
            // history.push('/login');
            // alert("hardik")
        }
    }, [props, history]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setQulifi({ ...qulifi, [name]: value });
    };

    const validate = (values) => {
        let errors = {};
        if (!values.department_name) {
            errors.department_name = "Cannot be blank";
        }
        if (!values.paid_leave_allowed) {
            errors.paid_leave_allowed = "Cannot be blank";
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(qulifi));
        setIsSubmitting(true);
    };

    useEffect(() => {
        const qulification = async () => {
            swal({
                title: "Loading",
                text: "Please wait...",
                buttons: false,
                closeOnClickOutside: false,
                closeOnEsc: false,
                width: "10%",
                content: {
                    element: "img",
                    attributes: {
                        src: "https://www.boasnotas.com/img/loading2.gif",
                        style: "width: 50px;"
                    }
                }
            });
            try {
                let create = await AuthApi.Departmentcreate(qulifi);
                swal.close();
                if (create && create.status === true) {
                    history.push('/designation');
                } else {
                    swal({
                        title: "OOPS!",
                        icon: "error",
                        text: "Something went wrong, Please try later!"
                    });
                }
            } catch (error) {
                console.error("Error updating qulification:", error);
            }
        };

        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            qulification();
        }
    }, [formErrors, isSubmitting, history, qulifi]);

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="Designation"
                                    primaryPageLink="/designation"
                                    isSecondaryPage={true}
                                    secondaryPageName="Add Designation" />
                            </CardContent>
                        </Card>

                        {/* {
                            props.authToken === false ? <AuthCheckComponent authToken={props.authToken} /> : ""
                        } */}
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>

                                        <Typography variant="h2">Add Designation</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="department_name"
                                                        name="department_name"
                                                        label="Designation Name"
                                                        variant="outlined"
                                                        error={formErrors.department_name && true}
                                                        value={qulifi.department_name}
                                                        onChange={handleChange}
                                                        className={formErrors.department_name && "input-error"}
                                                    />
                                                    {formErrors.department_name && (
                                                        <span className="error">{formErrors.department_name}</span>
                                                    )}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="paid_leave_allowed"
                                                        name="paid_leave_allowed"
                                                        label="Allowed Paid Leave"
                                                        variant="outlined"
                                                        error={formErrors.paid_leave_allowed && true}
                                                        value={qulifi.paid_leave_allowed}
                                                        onChange={handleChange}
                                                        className={formErrors.paid_leave_allowed && "input-error"}
                                                    />
                                                    {formErrors.paid_leave_allowed && (
                                                        <span className="error">{formErrors.paid_leave_allowed}</span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4} style={{ display: 'flex' }}>
                                                    <Button type="submit" style={{ marginTop: "10px" }} variant="contained" color="primary">Save</Button>
                                                    <Button type="button" style={{ marginTop: "10px" }} onClick={(e) => { e.preventDefault(); history.push('/designation'); }}>Cancel</Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}></Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
        </div>
    );
};

export default Adddesignation;
