import React, { useState, useEffect } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
// import LoadderButton from "../../Layouts/LoadderButton";
import AuthApi from "../../Services/Authapi";
// import Paper from "@material-ui/core/Paper";
// import FontAwesomeIconComponent from "../../Layouts/FontAwesomeIconComponent";
// import { SignalCellularNull } from "@material-ui/icons";
// import Authapi from "../../Services/Authapi";
import swal from "sweetalert";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import { now } from "moment";
import moment from "moment";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import ls from "local-storage";
import { useLocation } from "react-router-dom";
import AuthCheckComponent from '../../Layouts/Token';

const iniTickets = {
  leaveType: "",
  ticketMesasge: "",
  dateRange: "",
  startDate: "",
  endDate: "",
  noOfDays: "",
  firstleavetype: "", 
  secondleavetype: "",
};

const AddTickets = (props) => {
  const classes = props;
  const [ticketValues, setTicketsValues] = useState(iniTickets);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const leaveDate = queryParams.get("date");

  useEffect(() => {
    if (leaveDate) {
      const formattedDate = leaveDate.split("-").reverse().join("-");
      setTicketsValues({
        ...ticketValues,
        startDate: formattedDate,
        // endDate: formattedDate,
        leaveType: "Loss Of Pay",
        // firstleavetype: "first-half",
        // secondleavetype: "first-half",
        ticketMesasge: "Left Early",
      });
    }
  }, [leaveDate]);

  useEffect(() => {  
    if (props.authToken === false) {
      // props.history.push("/login");
    }
  }, [props.authToken, props.history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketsValues({ ...ticketValues, [name]: value });
  };

  function dateDiffInDays(startDate, endDate) {
    return Math.round((endDate - startDate) / (1000, 60, 60 * 24));
  }
  const daysDiff = dateDiffInDays(
    new Date(ticketValues.startDate),
    new Date(ticketValues.endDate)
  );
  const today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const dsp = dateDiffInDays(new Date(date), new Date(ticketValues.startDate));


  const validate = (values) => {
    let errors = {};
    const regex = /^[a-zA-Z]/;
    const dateR = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;

    if (!values.leaveType) {
      errors.leaveType = "Cannot be blank";
    }

    if (!values.ticketMesasge) {
      errors.ticketMesasge = "Cannot be blank";
    } else if (!regex.test(values.ticketMesasge)) {
      errors.ticketMesasge = "Invalid ticketMesasge format";
    }

    if (!values.firstleavetype) {
      errors.firstleavetype = "Cannot be blank";
    } else if (!regex.test(values.firstleavetype)) {
      errors.firstleavetype = "Invalid ticketMesasge format";
    }

    if (!values.secondleavetype) {
      errors.secondleavetype = "Cannot be blank";
    } else if (!regex.test(values.secondleavetype)) {
      errors.secondleavetype = "Invalid ticketMesasge format";
    }

    if (!values.startDate) {
      errors.startDate = "Cannot be blank";
    } else if (!dateR.test(values.startDate)) {
      errors.startDate = "Invalid dob format";
    }

    if (!values.endDate) {
      errors.endDate = "Cannot be blank";
    } else if (!dateR.test(values.endDate)) {
      errors.endDate = "Invalid dob format";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    setFormErrors(validate(ticketValues));
    setIsSubmitting(true);
  };


  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      Tickets();
      // setIsLoading(false);
    }
  }, [formErrors]);

  // const Tickets = async () => {
  //   swal({
  //     title: "Loading",
  //     text: "Please wait...",
  //     buttons: false,
  //     closeOnClickOutside: false,
  //     closeOnEsc: false,
  //     width: "10%",
  //     content: {
  //       element: "img",
  //       attributes: {
  //         src: "https://www.boasnotas.com/img/loading2.gif",
  //         style: "width: 50px;" // Adjust the width as needed
  //       }
  //     }
  //   });
  //   // 
  //   let create1 = await AuthApi.createTicket(ticketValues);
  //   swal.close();
  //   if (create1 && create1.status === true) {
  //     props.history.push("/tickets");
  //   } else {
  //     swal.close();
  //     swal({
  //       title: "OOPS!",
  //       icon: "fail",
  //       message: "Something went wrong, Please try later!",
  //     });
  //   }
  // };

  const Tickets = async () => {
    swal({
      title: "Loading",
      text: "Please wait...",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      width: "10%",
      content: {
        element: "img",
        attributes: {
          src: "https://www.boasnotas.com/img/loading2.gif",
          style: "width: 50px;"
        }
      }
    });
    try {
      let create1 = await AuthApi.createTicket(ticketValues);
      swal.close();
      if (create1 && create1.status === true) {
        swal("Success", "Ticket created successfully!", "success");
        props.history.push("/tickets");
      } else {
        swal("Error", create1, "error"); 
      }
    } catch (error) {
      swal("Error", "An error occurred, Please try again later!", "error");
    }
  };

  function diffday() {
    const a = moment(ticketValues.startDate);
    const b = moment(ticketValues.endDate);
    const dateRangeArr = [];
    var startDate = ticketValues.startDate;
    dateRangeArr.push(startDate);
    var endDate = ticketValues.endDate;
    dateRangeArr.push(endDate);
    ticketValues.dateRange = dateRangeArr;
    if (ticketValues.firstleavetype === "first-half" && ticketValues.secondleavetype === "first-half") {
      ticketValues.noOfDays = 0.5;
    } else if (ticketValues.firstleavetype === "second-half" && ticketValues.secondleavetype === "second-half") {
      ticketValues.noOfDays = 0.5;
    }
    else {
      ticketValues.noOfDays = b.diff(a, "days") + 1;
    }
  }

  return (
    <div>
      <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        component={
          <div>
            <Card className={classes.root} style={{ marginBottom: "3%" }}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Leaves Ticket"
                  primaryPageLink="/tickets"
                  isSecondaryPage={true}
                  secondaryPageName="Add Leave Ticket"
                />
              </CardContent>
            </Card>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="h2">Add Tickets</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid container className={classes.root} spacing={5}>
                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Leave-Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="leave-Type"
                              name="leaveType"
                              error={formErrors.leaveType && true}
                              value={ticketValues.leaveType}
                              onChange={handleChange}
                              className={formErrors.leaveType && "input-error"}
                            >
                              <MenuItem value="Loss Of Pay">
                                Loss Of Pay
                              </MenuItem>
                              {/* <MenuItem value="Casual Leave">
                                Casual Leave
                              </MenuItem> */}
                              {/* <MenuItem value="sick-leave">Sick leave</MenuItem>
                                                            <MenuItem value="early-leave">Early leave</MenuItem> */}
                            </Select>
                          </FormControl>
                          {formErrors.leaveType && (
                            <span className="error">
                              {formErrors.leaveType}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="date"
                            variant="outlined"
                            label="Start-date"
                            name="startDate"
                            type="date"
                            error={formErrors.startDate && true}
                            value={ticketValues.startDate}
                            onChange={handleChange}
                            className={formErrors.startDate && "input-error"}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {formErrors.startDate && (
                            <span className="error">
                              {formErrors.startDate}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select-Half
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Select-Half"
                              error={formErrors.firstleavetype && true}
                              name="firstleavetype"
                              // className={classes.firstleavetype}
                              // error={formErrors.leaveType && true}
                              value={ticketValues.firstleavetype}
                              onChange={handleChange}
                            // className={formErrors.leaveType && "input-error"}
                            >
                              <MenuItem value="first-half">First Half</MenuItem>
                              <MenuItem value="second-half">
                                Second Half
                              </MenuItem>
                            </Select>
                            {formErrors.firstleavetype && (
                              <span className="error">
                                {formErrors.firstleavetype}
                              </span>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="date"
                            variant="outlined"
                            label="End-date"
                            name="endDate"
                            type="date"
                            error={formErrors.endDate && true}
                            value={ticketValues.endDate}
                            onChange={handleChange}
                            className={formErrors.endDate && "input-error"}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {formErrors.endDate && (
                            <span className="error">{formErrors.endDate}</span>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select-Half
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              label="Select-Half"
                              name="secondleavetype"
                              error={formErrors.secondleavetype && true}
                              // error={formErrors.leaveType && true}
                              value={ticketValues.secondleavetype}
                              onChange={handleChange}
                            // className={formErrors.leaveType && "input-error"}
                            >
                              <MenuItem value="first-half">First Half</MenuItem>
                              <MenuItem value="second-half">
                                Second Half
                              </MenuItem>
                            </Select>
                            {formErrors.secondleavetype && (
                              <span className="error">
                                {formErrors.secondleavetype}
                              </span>
                            )}
                          </FormControl>
                          {/* {formErrors.leaveType && (
                                                        <span className="error">{formErrors.leaveType}</span>
                                                    )} */}
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            id="ticketMesasge"
                            name="ticketMesasge"
                            label="Reason for Leave"
                            variant="outlined"
                            error={formErrors.ticketMesasge && true}
                            value={ticketValues.ticketMesasge}
                            onChange={handleChange}
                            className={
                              formErrors.ticketMesasge && "input-error"
                            }
                          />
                          {formErrors.ticketMesasge && (
                            <span className="error">
                              {formErrors.ticketMesasge}
                            </span>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container className={classes.root} spacing={3}>
                        <Grid item xs={4} style={{ display: "flex" }}>
                          <Button
                            type="submit"
                            onClick={(e) => {
                              diffday();
                            }}
                            style={{ marginTop: "10px" }}
                            variant="contained"
                            color="primary"
                          >
                            Save
                          </Button>
                          <Button
                            type="button"
                            style={{ marginTop: "10px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              props.history.push("/tickets");
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </div>
        }
      />
      <Footer />
    </div>
  );
};

export default AddTickets;
