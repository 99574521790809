import React, { useState } from 'react';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import { Card, CardContent, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Typography } from '@material-ui/core';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Authapi from '../../Services/Authapi';
import swal from 'sweetalert';
import AuthCheckComponent from '../../Layouts/Token';

export default function Reviewformadd(props) {
    const [doc, setDoc] = useState({
        title: '',
        inputs: [{ type: '', label: '', options: [] }]
    });
    const [postTitles, setPostTitles] = useState([{ title: '', inputs: [{ type: '', label: '' }] }]);
    const [formErrors, setFormErrors] = useState({});
    const classes = {};

    const handleSubmit = (e) => {
        e.preventDefault();
        createLeaves();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDoc(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleMainInputChange = (inputIndex, field, value) => {
        const newInputs = [...doc.inputs];
        newInputs[inputIndex][field] = value;
        if (field === 'type' && (value === 'radio' || value === 'checkbox')) {
            newInputs[inputIndex].options = newInputs[inputIndex].options || []; // Initialize options if type is radio or checkbox
        } else if (field === 'type') {
            newInputs[inputIndex].options = [];
        }
        setDoc(prevState => ({
            ...prevState,
            inputs: newInputs
        }));
    };

    const handleOptionChange = (inputIndex, optionIndex, value) => {
        const newInputs = [...doc.inputs];
        newInputs[inputIndex].options[optionIndex] = value;
        setDoc(prevState => ({
            ...prevState,
            inputs: newInputs
        }));
    };

    const addOption = (inputIndex) => {
        setDoc(prevState => {
            const newInputs = [...prevState.inputs];
            if (!newInputs[inputIndex]) {
                newInputs[inputIndex] = { type: '', label: '', options: [] };
            }
            newInputs[inputIndex].options = newInputs[inputIndex].options || [];
            newInputs[inputIndex].options.push(''); // Add a new empty option
            return { ...prevState, inputs: newInputs };
        });
    };

    const removeOption = (inputIndex, optionIndex) => {
        const newInputs = [...doc.inputs];
        newInputs[inputIndex].options.splice(optionIndex, 1);
        setDoc(prevState => ({
            ...prevState,
            inputs: newInputs
        }));
    };

    const addMainInputField = () => {
        setDoc(prevState => ({
            ...prevState,
            inputs: [...prevState.inputs, { type: '', label: '', options: [] }]
        }));
    };

    const removeMainInputField = (inputIndex) => {
        const newInputs = [...doc.inputs];
        newInputs.splice(inputIndex, 1);
        setDoc(prevState => ({
            ...prevState,
            inputs: newInputs
        }));
    };

    const handlePostTitleChange = (index, value) => {
        const newPostTitles = [...postTitles];
        newPostTitles[index].title = value;
        setPostTitles(newPostTitles);
    };

    const handleInputChange = (postIndex, inputIndex, field, value) => {
        const newPostTitles = [...postTitles];
        if (field === 'options') {
            newPostTitles[postIndex].inputs[inputIndex][field] = value.split(',').map(option => option.trim());
        } else {
            newPostTitles[postIndex].inputs[inputIndex][field] = value;
            if (field === 'type' && (value !== 'radio' && value !== 'checkbox')) {
                newPostTitles[postIndex].inputs[inputIndex].options = [];
            }
        }
        setPostTitles(newPostTitles);
    };

    const addPostTitleField = () => {
        setPostTitles([...postTitles, { title: '', inputs: [{ type: '', label: '' }] }]);
    };

    const removePostTitleField = (index) => {
        const newPostTitles = [...postTitles];
        newPostTitles.splice(index, 1);
        setPostTitles(newPostTitles);
    };

    const addInputField = (postIndex) => {
        const newPostTitles = [...postTitles];
        newPostTitles[postIndex].inputs.push({ type: '', label: '' });
        setPostTitles(newPostTitles);
    };

    const removeInputField = (postIndex, inputIndex) => {
        const newPostTitles = [...postTitles];
        newPostTitles[postIndex].inputs.splice(inputIndex, 1);
        setPostTitles(newPostTitles);
    };

    const handlePostOptionChange = (postIndex, inputIndex, optionIndex, value) => {
        const newPostTitles = [...postTitles];
        newPostTitles[postIndex].inputs[inputIndex].options[optionIndex] = value;
        setPostTitles(newPostTitles);
    };

    const addPostOption = (postIndex, inputIndex) => {
        setPostTitles(prevState => {
            const newPostTitles = [...prevState];
            const input = newPostTitles[postIndex].inputs[inputIndex];
            input.options = input.options || [];
            input.options.push('');
            return newPostTitles;
        });
    };

    const removePostOption = (postIndex, inputIndex, optionIndex) => {
        const newPostTitles = [...postTitles];
        newPostTitles[postIndex].inputs[inputIndex].options.splice(optionIndex, 1);
        setPostTitles(newPostTitles);
    };

    const createLeaves = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });

        try {
            const transformedInputs = doc.inputs.map(input => ({
                label: input.label,
                type: input.type,
                options: (input.type === 'radio' || input.type === 'checkbox') ? input.options : []
            }));

            const transformedPostTitles = postTitles.map(postTitle => {
                return postTitle.inputs.map(input => ({
                    title: postTitle.title,
                    label: input.label,
                    type: input.type,
                    options: (input.type === 'radio' || input.type === 'checkbox') ? input.options : []
                }));
            }).flat();

            const postData = {
                form_name: doc.title,
                form_details: transformedInputs,
                form_content: transformedPostTitles
            };

            let create = await Authapi.Reviewformadd(postData);
            swal.close();
            if (create && create.status === true) {
                props.history.push("/Reviewformlist");
            } else {
                swal({
                    title: "OOPS!",
                    icon: "fail",
                    message: "Something went wrong, Please try later!",
                });
            }
        } catch (error) {
            console.error("Error updating role:", error);
        }
    };




    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                setAuthUser={props.setAuthUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb {...props}
                                    primaryPageName="Generate Form"
                                    primaryPageLink="/dashboard"
                                    isSecondaryPage={false}
                                    secondaryPageName="" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}>Generate Form</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        label="Name"
                                                        name="title"
                                                        variant="outlined"
                                                        error={formErrors.title && true}
                                                        value={doc.title}
                                                        onChange={handleChange}
                                                        className={formErrors.title && "input-error"}
                                                    />
                                                    {formErrors.title && (
                                                        <span className="error">{formErrors.title}</span>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {doc.inputs.map((input, inputIndex) => (
                                                <Grid container spacing={2} key={inputIndex}>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            fullWidth
                                                            label={`Input Label ${inputIndex + 1}`}
                                                            value={input.label}
                                                            onChange={(e) => handleMainInputChange(inputIndex, 'label', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>Input Type</InputLabel>
                                                            <Select
                                                                value={input.type}
                                                                onChange={(e) => handleMainInputChange(inputIndex, 'type', e.target.value)}
                                                            >
                                                                <MenuItem value="text">Text</MenuItem>
                                                                <MenuItem value="date">Date</MenuItem>
                                                                <MenuItem value="email">Email</MenuItem>
                                                                <MenuItem value="number">Number</MenuItem>
                                                                <MenuItem value="textarea">Textarea</MenuItem>
                                                                <MenuItem value="checkbox">Checkbox</MenuItem>
                                                                <MenuItem value="radio">Radio</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button
                                                            onClick={() => removeMainInputField(inputIndex)}
                                                            variant="contained"
                                                            style={{
                                                                color: "#fff",
                                                                backgroundColor: "#d12c2c",
                                                                marginTop: "10px"
                                                            }}>
                                                            Remove
                                                        </Button>
                                                    </Grid>
                                                    {(input.type === 'radio' || input.type === 'checkbox') && (
                                                        <>

                                                            <Grid item xs={12}>
                                                                {input.options && input.options.map((option, optionIndex) => (
                                                                    <Grid container spacing={2} key={optionIndex}>
                                                                        <Grid item xs={8}>
                                                                            <TextField
                                                                                fullWidth
                                                                                label={`Option ${optionIndex + 1}`}
                                                                                value={option}
                                                                                onChange={(e) => handleOptionChange(inputIndex, optionIndex, e.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <Button
                                                                                onClick={() => removeOption(inputIndex, optionIndex)}
                                                                                variant="contained"
                                                                                style={{
                                                                                    color: "#fff",
                                                                                    backgroundColor: "#d12c2c",
                                                                                    marginTop: "10px"
                                                                                }}>
                                                                                Remove
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                                <Button
                                                                    onClick={() => addOption(inputIndex)}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{ marginTop: "10px" }}>
                                                                    Add Option
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            ))}
                                            <Button
                                                onClick={addMainInputField}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginTop: "10px" }}>
                                                Add Input
                                            </Button>
                                            {postTitles.map((postTitle, postIndex) => (
                                                <div key={postIndex}>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={10}>
                                                            <TextField
                                                                fullWidth
                                                                label={`Form Title ${postIndex + 1}`}
                                                                value={postTitle.title}
                                                                onChange={(e) => handlePostTitleChange(postIndex, e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Button
                                                                onClick={() => removePostTitleField(postIndex)}
                                                                variant="contained"
                                                                style={{
                                                                    color: "#fff",
                                                                    backgroundColor: "#d12c2c",
                                                                    marginTop: "10px"
                                                                }}>
                                                                Remove
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    {postTitle.inputs.map((input, inputIndex) => (
                                                        <Grid container spacing={2} key={inputIndex}>
                                                            <Grid item xs={5}>
                                                                  <TextField
                                                                    fullWidth
                                                                    label={`Input Label ${inputIndex + 1}`}
                                                                    value={input.label}
                                                                    onChange={(e) => handleInputChange(postIndex, inputIndex, 'label', e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel>Input Type</InputLabel>
                                                                    <Select
                                                                        value={input.type}
                                                                        onChange={(e) => handleInputChange(postIndex, inputIndex, 'type', e.target.value)}
                                                                    >
                                                                        <MenuItem value="text">Text</MenuItem>
                                                                        <MenuItem value="date">Date</MenuItem>
                                                                        <MenuItem value="email">Email</MenuItem>
                                                                        <MenuItem value="number">Number</MenuItem>
                                                                        <MenuItem value="textarea">Textarea</MenuItem>
                                                                        <MenuItem value="checkbox">Checkbox</MenuItem>
                                                                        <MenuItem value="radio">Radio</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Button
                                                                    onClick={() => removeInputField(postIndex, inputIndex)}
                                                                    variant="contained"
                                                                    style={{
                                                                        color: "#fff",
                                                                        backgroundColor: "#d12c2c",
                                                                        marginTop: "10px"
                                                                    }}>
                                                                    Remove
                                                                </Button>
                                                            </Grid>
                                                            {(input.type === 'radio' || input.type === 'checkbox') && (
                                                                <Grid item xs={12}>
                                                                    {input.options && input.options.map((option, optionIndex) => (
                                                                        <Grid container spacing={2} key={optionIndex}>
                                                                            <Grid item xs={8}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label={`Option ${optionIndex + 1}`}
                                                                                    value={option}
                                                                                    onChange={(e) => handlePostOptionChange(postIndex, inputIndex, optionIndex, e.target.value,"")}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <Button
                                                                                    onClick={() => removePostOption(postIndex, inputIndex, optionIndex)}
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        color: "#fff",
                                                                                        backgroundColor: "#d12c2c",
                                                                                        marginTop: "10px"
                                                                                    }}>
                                                                                    Remove
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}
                                                                    <Button
                                                                        onClick={() => addPostOption(postIndex, inputIndex)}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        style={{ marginTop: "10px" }}>
                                                                        Add Option
                                                                    </Button>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    ))}
                                                    <Button
                                                        onClick={() => addInputField(postIndex)}
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginTop: "10px" }}>
                                                        Add Input
                                                    </Button>
                                                </div>
                                            ))}
                                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                <Button onClick={addPostTitleField} variant="contained" color="primary">
                                                    Add Form Title
                                                </Button>
                                            </Grid>
                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4}>
                                                    <Button type="submit" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} variant="contained" color="primary">Save</Button>
                                                    <Button type="button" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} onClick={(e) => { e.preventDefault(); props.history.push('/Reviewformlist') }}>Cancel</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                }
            />
            <Footer {...props} />
        </div>
    );
}
