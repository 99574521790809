import React, { useState, useEffect } from 'react';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Typography from '@material-ui/core/Typography';
import { colors, TextField } from '@material-ui/core';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import LoadderButton from '../../Layouts/LoadderButton';
import AuthApi from '../../Services/Authapi';
// import Paper from '@material-ui/core/Paper';
import FontAwesomeIconComponent from '../../Layouts/FontAwesomeIconComponent';
// import { SignalCellularNull } from '@material-ui/icons';
// import Authapi from '../../Services/Authapi';
import swal from 'sweetalert';
import AuthCheckComponent from '../../Layouts/Token';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import { now } from 'moment';
import moment from 'moment';
// import GridList from '@material-ui/core/GridList';
// import { makeStyles } from '@material-ui/core/styles';
// import ClearIcon from '@material-ui/icons/Clear';
// import IconButton from '@material-ui/core/IconButton';




const iniEvent = {
    upcomingEventName: "",
    upcomingEventdate: "",
    description: "",
    image: null
}


const UpcomingEvent = (props) => {
    const classes = props;

    const [eventValues, setEventsValues] = useState(iniEvent);
    // const [selectedImages, setSelectedImages] = useState({ image: null });
    // const [stringImages, setStringImages] = useState({ images: [] });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);


    useEffect(() => {
        if (props && props.authToken === false) {
            // props.history.push('/login');
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEventsValues({ ...eventValues, [name]: value });

        if (name === "upcomingEventdate") {
            const selectedDate = moment(value, "YYYY-MM-DD");
            const dayOfWeek = selectedDate.day();

            if (dayOfWeek === 6 || dayOfWeek === 0) { // 6 = Saturday, 0 = Sunday
                swal({
                    title: "Invalid Date",
                    text: "Weekends are not allowed",
                    icon: "error",
                });
            }
        }
    };


    const uploadSingleFile = async (e) => {
        const postData = new FormData();
        postData.append('file', e.target.files[0]);
        let updaImg = await AuthApi.uploadEventImg(postData);
        if (updaImg && updaImg.status === true) {
            setEventsValues({
                ...eventValues,
                image: updaImg.data.image_url,
                // imageName: updaImg.data.image_name
            })
        }
    }


    const removeImg = async (e) => {
        let imageLink = eventValues.image;
        imageLink = imageLink.substr(imageLink.indexOf('/', 7) + 1)
        let remImg = await AuthApi.upcomingdeleteImg(imageLink);
        if (remImg && remImg.status === true) {
            setEventsValues({
                ...eventValues,
                image: null,
                // imageName: null
            })
        } 
    }


    // const removeImage = (e) => {
    //     const stringImages1 = stringImages.images
    //     const selectedImages1 = selectedImages
    //     stringImages1.splice(e, 1)
    //     selectedImages1.splice(e, 1)


    //     setSelectedImages((prevImages) => prevImages = selectedImages1)
    //     setStringImages({ ...stringImages, images: stringImages1 });
    // }

    function dateDiffInDays(startDate, endDate) {
        return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
    }
    const dsp = dateDiffInDays(new Date(), new Date(eventValues.upcomingEventdate))


    const validate = (values) => {
        let errors = {};
        const regex = /^[a-zA-Z]/;
        const dateR = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;

        if (!values.upcomingEventName) {
            errors.upcomingEventName = "Cannot be blank";
        } else if (!regex.test(values.upcomingEventName)) {
            errors.upcomingEventName = "Invalid upcomingEventName format";
        }

        if (!values.upcomingEventdate) {
            errors.upcomingEventdate = "Cannot be blank";
        } else if (!dateR.test(values.upcomingEventdate)) {
            errors.upcomingEventdate = "Invalid upcomingEventdate format";
        } else {
            const selectedDate = moment(values.upcomingEventdate, "YYYY-MM-DD");
            const dayOfWeek = selectedDate.day();
            if (dayOfWeek === 6 || dayOfWeek === 0) {
                errors.upcomingEventdate = "Weekends are not allowed";
            }
        }

        if (!values.description) {
            errors.description = "Cannot be blank";
        } else if (!regex.test(values.description)) {
            errors.description = "Invalid description format";
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(eventValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
        } else if (errors.upcomingEventdate) {
            swal({
                title: "Invalid Date",
                text: errors.upcomingEventdate,
                icon: "error",
            });
        }
    };

    useEffect( () => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
             upcomingEvent();
        }
    }, [formErrors])


    const upcomingEvent = async () => {
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;" 
                }
            }
        });
        try {
            let create1 = await AuthApi.createUpcomingEvent(eventValues);
            swal.close();
            if (create1 && create1.status === true) {
                props.history.push('/upcoming');
            } else {
                swal({
                    title: "OOPS!",
                    icon: "fail",
                    message: "Something went wrong, Please try later!"
                })
            }
        } catch (error) {
            console.error("Error updating upcoming:", error);
        }
    }


    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb
                                    {...props}
                                    primaryPageName="Upcoming Events"
                                    primaryPageLink="/upcoming"
                                    isSecondaryPage={true}
                                    secondaryPageName="Add Upcoming Event" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2">Add Upcoming Event</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit} >
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="upcomingEventName"
                                                        name="upcomingEventName"
                                                        label="Event Name"
                                                        variant="outlined"
                                                        error={formErrors.upcomingEventName && true}
                                                        value={eventValues.upcomingEventName}
                                                        onChange={handleChange}
                                                        className={formErrors.upcomingEventName && "input-error"}

                                                    />
                                                    {formErrors.upcomingEventName && (
                                                        <span className="error">{formErrors.upcomingEventName}</span>
                                                    )}
                                                </Grid>


                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        id="date"
                                                        variant="outlined"
                                                        label="Event Date"
                                                        name="upcomingEventdate"
                                                        type="date"
                                                        error={formErrors.upcomingEventdate && true}
                                                        value={eventValues.upcomingEventdate}
                                                        onChange={handleChange}
                                                        className={formErrors.upcomingEventdate && "input-error"}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            min: moment().add(1, 'days').format('YYYY-MM-DD')
                                                        }}
                                                    />
                                                    {formErrors.upcomingEventdate && (
                                                        <span className="error">{formErrors.upcomingEventdate}</span>
                                                    )}
                                                </Grid>
                                            </Grid>

                                            <Grid container className={classes.root} spacing={5}>
    <Grid item xs={6}>
        <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label="Event Details"
            name="description"
            error={formErrors.description && true}
            value={eventValues.description}
            onChange={handleChange}
            className={formErrors.description && "input-error"}
        />
        {formErrors.description && (
            <span className="error">{formErrors.description}</span>
        )}
    </Grid>

                                                <Grid item xs={6} style={{ display: 'flex' }} >
                                                    {
                                                        (eventValues.image === null) ?
                                                            <div>
                                                                <input
                                                                    accept="image/*"
                                                                    className={classes.input}
                                                                    name="photo"
                                                                    id="contained-button-file"
                                                                    type="file"
                                                                    onChange={(e) => uploadSingleFile(e)}
                                                                    style={{ display: 'none' }}
                                                                />
                                                                <label htmlFor="contained-button-file">
                                                                    <Button variant="contained" color="primary" component="span" > Upload Event Picture </Button>
                                                                </label>
                                                            </div>
                                                            :
                                                            <div>
                                                                <Card className={classes.root}>
                                                                    <CardContent style={{ width: "550px" }}>
                                                                        <Grid container className={classes.root} spacing={5}>
                                                                            <Grid item xs={6}>
                                                                                <img src={eventValues.image} alt="Event image" width="100%" height="100%" />
                                                                            </Grid>
                                                                            <input
                                                                                className={classes.input}
                                                                                name="photo"
                                                                                id="photo"
                                                                                type="hidden"
                                                                                value={eventValues.image ? eventValues.image : ""}
                                                                            />
                                                                            <Grid item xs={6}>
                                                                                <Button style={{ marginTop: "70px" }}
                                                                                    onClick={(e) => removeImg(e)}
                                                                                >
                                                                                    <FontAwesomeIconComponent classes="fa fa-trash" colorName="primary" fontSize={"small"} /></Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </CardContent>
                                                                </Card>
                                                            </div>
                                                    }
                                                </Grid>



                                            </Grid>

                                            {/* <Grid container className={classes.root} spacing={5} >
                                                <Grid item xs={6}>
                                                    <div style={{ float: "left" }}>
                                                        <Card className={classes.root}>
                                                            <Grid item xs={12}>
                                                                <div>
                                                                    <img src={eventValues.image} alt="Event image" width="100" height="100" />
                                                                </div>
                                                            </Grid>
                                                        </Card>
                                                    </div>
                                                </Grid>
                                            </Grid> */}



                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4} style={{ display: 'flex' }}>
                                                    <Button type="submit" variant="contained"style={{ marginTop: "10px" }} color="primary">Save</Button>
                                                    <Button type="button" style={{ marginTop: "10px" }} onClick={(e) => { e.preventDefault(); props.history.push('/upcoming') }}>Cancel</Button>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}></Grid>
                                            </Grid>
                                        </form>

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </div>
                } />
            <Footer />
        </div>
    )

}

export default UpcomingEvent;


