import React, { useState, useEffect } from 'react';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import './Form360.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Breadcrumb from '../../Layouts/Breadcrumb';
import CardContent from '@material-ui/core/CardContent';
import { Card, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Authapi from '../../Services/Authapi';
import swal from 'sweetalert';
import ls from 'local-storage';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@material-ui/core/Checkbox';
import AuthCheckComponent from '../../Layouts/Token';
import { Visibility, Delete } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function Form360(props) {
  const classes = {};
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentSection, setCurrentSection] = useState(-1);
  const [responses, setResponses] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [data, setData] = useState();
  const [reviewformlist, setReviewformlist] = useState();
  const [reviewformlistdata, setReviewformlistdata] = useState();
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [viewMode, setViewMode] = useState(null);
  const [errors, setErrors] = useState({});
  const [totalSelections, setTotalSelections] = useState(0);
  const [pageSize, setPageSize] = useState(5);


  useEffect(() => {
    Reviewformlisttitle()
    Reviewformdynamiclistid()
  }, []);


  const Reviewformlisttitle = async () => {
    try {
      let role = await Authapi.Reviewformlist();
      setReviewformlist(role.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const Reviewformdynamiclistid = async () => {
    const userid = ls("user").id
    const role = ls("roles")
    console.log(role)
    try {
      if (role === "Super Admin") {
        let role = await Authapi.Reviewformdynamiclist(userid);
        setReviewformlistdata(role.data);
      } else {
        let role = await Authapi.Reviewformdynamiclistid(userid);
        setReviewformlistdata(role.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDetailChange = (label, value) => {
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [label]: value
    }));
  };

  const handleResponseChange = (sectionIndex, questionIndex, value, label, type) => {
    setResponses((prevResponses) => {
      const currentSectionResponses = prevResponses[sectionIndex] || {};
      const currentQuestionResponses = currentSectionResponses[label] || "";

      let updatedQuestionResponses;
      if (type === 'checkbox') {
        const selectedOptions = currentQuestionResponses.split(',').filter(item => item);
        if (selectedOptions.includes(value)) {
          updatedQuestionResponses = selectedOptions.filter(item => item !== value).join(',');
        } else {
          updatedQuestionResponses = [...selectedOptions, value].join(',');
        }
      } else {
        updatedQuestionResponses = value;
      }

      return {
        ...prevResponses,
        [sectionIndex]: {
          ...currentSectionResponses,
          [label]: updatedQuestionResponses
        }
      };
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (currentSection === -1 && data?.form_details) {
      data.form_details.forEach(detail => {
        if (detail.type === 'checkbox') {
          const selectedOptions = responses[currentSection]?.[detail.label]?.split(',').filter(item => item);
          // console.log(selectedOptions)
          if (!selectedOptions || selectedOptions.length === 0) {
            newErrors[detail.label] = `${detail.label} is required`;
          }
        } else if (!formDetails[detail.label]) {
          newErrors[detail.label] = `${detail.label} is required`;
        }
      });
    } else if (data && data.form_content) {
      const currentTitle = Array.from(new Set(data.form_content.map(content => content.title)))[currentSection];
      data.form_content
        .filter(content => content.title === currentTitle)
        .forEach(question => {
          const response = responses[currentSection]?.[question.label];
          if (question.type === 'checkbox') {
            const selectedOptions = response?.split(',').filter(item => item);
            if (!selectedOptions || selectedOptions.length === 0) {
              newErrors[question.label] = `${question.label} is required`;
            }
          } else if (!response) {
            newErrors[question.label] = `${question.label} is required`;
          }
        });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      setViewMode("list");
    } else {
      console.log("Validation failed");
    }
  };

  const handleNext = async () => {
    if (validateForm()) {
      if (currentSection === -1) {
        setCurrentSection(currentSection + 1);
      } else if (data && data.form_content) {
        const uniqueTitles = [...new Set(data.form_content.map(item => item.title))];
        if (currentSection < uniqueTitles.length - 1) {
          setCurrentSection(currentSection + 1);
        } else if (currentSection === uniqueTitles.length - 1) {
          await createLeaves();
        }
      }
    }
  };

  const handlePrevious = () => {
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
    }
  };

  const createLeaves = async () => {
    const finalPostData = {
      ...formDetails,
      ...Object.values(responses).reduce((acc, section) => ({ ...acc, ...section }), {})
    };
    swal({
      title: "Loading",
      text: "Please wait...",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      width: "10%",
      content: {
        element: "img",
        attributes: {
          src: "https://www.boasnotas.com/img/loading2.gif",
          style: "width: 50px;"
        }
      }
    });

    try {
      let create = await Authapi.Reviewformdynamicadd(finalPostData, data?.form_name);
      swal.close();
      if (create && create.status === true) {
        // props.history.push("/Form360");
        setViewMode("list")
        Reviewformdynamiclistid()
      } else {
        swal({
          title: "OOPS!",
          icon: "fail",
          message: "Something went wrong, Please try later!",
        });
      }
    } catch (error) {
      console.error("Error updating role:", error);
    }
  };

  const handleFormSelect = (event) => {
    const formId = event.target.value;
    setSelectedFormId(formId);
    fetchFormDetailsById(formId);
  };

  const fetchFormDetailsById = async (formId) => {
    try {
      let role = await Authapi.ReviewformlistgetformbyID(formId);
      setData(role.data);
    } catch (error) {
      console.error('Error fetching form details:', error);
    }
  };

  const handleViewClick = (data) => {
    setSelectedData(data);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedData(null);
  };

  const handleViewModeChange = (event) => {
    setViewMode(event.target.value);
    if (event.target.value === "addForm") {
      setIsSubmitted(false);
      resetForm();
    }
  };

  const resetForm = () => {
    setFormDetails({});
    setResponses({});
    setCurrentSection(-1);
    setErrors({});
  };

  const handleDeleteClick = async (formId) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"]
    }).then(async (confirm) => {
      if (confirm) {
        let currentPermission = await Authapi.Reviewformdynamicdelete(formId);
        if (currentPermission && currentPermission.status === true) {
          Reviewformdynamiclistid()
          swal("Success", "Form deleted successfully!", "success");
        }
      }
    });
  };

  const columns = [
    {
      field: 'form_name',
      headerName: 'Form Title',
      flex: 1,
      headerClassName: 'header-red'
    },
    {
      field: 'first_name',
      headerName: 'User name',
      flex: 1,
      headerClassName: 'header-red'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerClassName: 'header-red',
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleViewClick(params.row)}
          >
            <Visibility />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
            style={{ marginLeft: '10px' }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  const User = ls("user");
  const userpermissions = (User && Array.isArray(User.roles) && User.roles[0] && Array.isArray(User.roles[0].permissions))
    ? User.roles[0].permissions.map(permissionObj => permissionObj.name)
    : [];


  return (
    <div className="form360-container">
      <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        setAutUser={props.setAutUser}
        component={
          <>
            <Card className={classes.card}>
              <CardContent>
                <Breadcrumb {...props}
                  primaryPageName="Feedback Forms"
                  primaryPageLink="/dashboard"
                  isSecondaryPage={false}
                  secondaryPageName="" />
              </CardContent>
            </Card>
            <FormControl variant="outlined" className="form-control" style={{ width: "82%", marginTop: '20px' }}>
              <InputLabel>Choose Action</InputLabel>
              <Select
                onChange={handleViewModeChange}
                value={viewMode || ''}
                className="styled-select"
                label="Choose Action"
              >
                <MenuItem value="" disabled>Select an action</MenuItem>
                <MenuItem value="addForm">Add Form</MenuItem>
                {userpermissions.includes("Form360.List") &&
                  <MenuItem value="list">List</MenuItem>
                }
              </Select>
            </FormControl>

            {viewMode === 'addForm' && (
              <div style={{ marginTop: '62px' }}>
                <FormControl variant="outlined" className="form-control" style={{ width: "82%" }}>
                  <InputLabel>Select a form</InputLabel>
                  <Select
                    onChange={handleFormSelect}
                    value={selectedFormId || ''}
                    className="styled-select"
                    label="Select a form"
                  >
                    <MenuItem value="" disabled>Select a form</MenuItem>
                    {reviewformlist && reviewformlist.map((form) => (
                      <MenuItem key={form.id} value={form.id}>
                        {form.form_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="form-container" style={{ marginBottom: '80px', marginTop: "45px" }}>
                  <form onSubmit={handleSubmit}>
                    <Typography variant="h4" style={{ color: '#1b6e95', marginBottom: '20px' }}>
                      {data?.form_name}
                    </Typography>

                    {currentSection === -1 && data?.form_details && (
                      <>
                        <div className="form-row">
                          {data.form_details.map((detail, index) => (
                            <div className="form-group" key={index}>
                              <label>{detail.label}: *</label>

                              {detail.type === 'radio' ? (
                                <RadioGroup
                                  name={detail.label}
                                  onChange={(e) => handleDetailChange(detail.label, e.target.value)}
                                  className="form-input"
                                  style={{ display: "ruby" }}
                                >
                                  {detail.options && detail.options.map((option, idx) => (
                                    <FormControlLabel
                                      key={idx}
                                      value={option}
                                      control={<Radio />}
                                      label={option}
                                    />
                                  ))}
                                </RadioGroup>
                              ) : detail.type === 'checkbox' ? (
                                <div className="checkbox-group">
                                  {detail.options && detail.options.map((option, idx) => (
                                    <FormControlLabel
                                      key={idx}
                                      style={{ display: "ruby" }}
                                      control={
                                        <Checkbox
                                          checked={responses[currentSection]?.[detail.label]?.split(',').includes(option) || false}
                                          onChange={(e) => handleResponseChange(currentSection, idx, option, detail.label, 'checkbox')}
                                        />
                                      }
                                      label={option}
                                    />
                                  ))}
                                </div>
                              ) : (
                                <input
                                  type={detail.type}
                                  name={detail.label}
                                  required
                                  onChange={(e) => handleDetailChange(detail.label, e.target.value)}
                                  className="form-input"
                                />
                              )}

                              {errors[detail.label] && <span className="error" style={{ color: 'red' }}>{errors[detail.label]}</span>}
                            </div>
                          ))}
                        </div>
                        <div className="form-group">
                          <Button variant="contained" color="primary" onClick={handleNext}>
                            Next
                          </Button>
                        </div>
                      </>
                    )}


                    {/* Feedback Sections */}
                    {currentSection >= 0 && currentSection < Array.from(new Set(data.form_content.map(content => content.title))).length && (
                      <>
                        <div className="progress-indicator">
                          {Array.from(new Set(data.form_content.map(content => content.title))).map((title, index) => (
                            <div
                              key={index}
                              className={`progress-step ${index === currentSection ? 'active' : ''}`}
                              onClick={() => setCurrentSection(index)}
                            >
                              <span className="step-number">{index + 1}</span>
                              <span className="step-title">{title}</span>
                            </div>
                          ))}
                        </div>
                        <div className="form-section">
                          <Typography variant="h5" style={{ color: '#1b6e95', marginBottom: '10px' }}>
                            {Array.from(new Set(data.form_content.map(content => content.title)))[currentSection]}
                          </Typography>
                          <table className="table">
                            <tbody>
                              {data.form_content.filter(content => content.title === Array.from(new Set(data.form_content.map(content => content.title)))[currentSection]).map((question, index) => (
                                <>
                                  <div key={index} style={{ marginBottom: '20px' }}>
                                    <Typography variant="h6" style={{ textAlign: "justify", marginBottom: '8px' }}>
                                      {question.label}
                                    </Typography>
                                    {question.type === 'checkbox' ? (
                                      question.options && question.options.map((option, idx) => (
                                        <FormControlLabel
                                          key={idx}
                                          control={
                                            <Checkbox
                                              checked={responses[currentSection]?.[question.label]?.split(',').includes(option) || false}
                                              onChange={(e) => handleResponseChange(currentSection, index, option, question.label, 'checkbox')}
                                            />
                                          }
                                          label={option}
                                          style={{ marginRight: '16px' }}
                                        />
                                      ))
                                    ) : question.type === 'radio' ? (
                                      <RadioGroup
                                        row
                                        name={`question-${currentSection}-${index}`}
                                        value={responses[currentSection]?.[question.label] || ""}
                                        onChange={(e) => handleResponseChange(currentSection, index, e.target.value, question.label, 'radio')}
                                      >
                                        {question.options && question.options.map((option, idx) => (
                                          <FormControlLabel
                                            key={idx}
                                            value={option ? option.toString() : ""}
                                            control={<Radio />}
                                            label={option}
                                            style={{ marginRight: '16px' }}
                                          />
                                        ))}
                                      </RadioGroup>
                                    ) : (
                                      <input
                                        type={question.type}
                                        name={question.label}
                                        value={responses[currentSection]?.[question.label] || ""}
                                        onChange={(e) => handleResponseChange(currentSection, index, e.target.value, question.label, question.type)}
                                        className="form-input"
                                        style={{
                                          width: '100%',
                                          padding: '10px',
                                          borderRadius: '4px',
                                          border: '1px solid #ccc',
                                          boxSizing: 'border-box',
                                          marginBottom: '10px'
                                        }}
                                      />
                                    )}
                                  </div>
                                  {errors[question.label] && <span className="error" style={{ color: 'red' }}>{errors[question.label]}</span>}<br /><br />
                                </>
                              ))}
                            </tbody>
                          </table>
                          <div className="form-navigation">
                            {currentSection > 0 && <Button variant="contained" onClick={handlePrevious}>Previous</Button>}
                            <Button variant="contained" color="primary" onClick={handleNext}>Next</Button>
                          </div>
                        </div>
                        {/* <Typography variant="h6" style={{ marginTop: '20px', textAlign: 'center' }}>
                          Total Radio Button Selections: {totalSelections}
                        </Typography> */}
                      </>
                    )}

                    {/* Thank You Section */}
                    {data && currentSection === Array.from(new Set(data.form_content.map(content => content.title))).length && (
                      <div>
                        <Typography variant="h4" style={{ color: '#4caf50' }}>
                          Thank you for your submission!
                        </Typography>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            )}

            {viewMode === 'list' && (
              <div style={{ marginBottom: '80px', marginTop: '62px' }}>
                <div style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={reviewformlistdata || []}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pagination
                    getRowId={(row) => row.id}
                  />
                </div>

                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm">
                  <DialogTitle
                    style={{
                      textAlign: "center",
                      padding: "5px 0",
                      backgroundColor: "#1b6e95",
                      color: "#fff",
                      // borderTopLeftRadius: "8px",
                      // borderTopRightRadius: "8px",
                      position: "relative"
                    }}
                  >
                    Form Details
                    <CloseIcon
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        cursor: "pointer",
                        color: "#fff",
                        backgroundColor: "#f44336",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                      onClick={handleCloseDialog}
                    />
                  </DialogTitle>
                  <DialogContent style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    {selectedData && (
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '10px' }}>
                          <div>
                            <strong>Form Name:</strong> {selectedData.form_name}
                          </div>
                          <div>
                            <strong>User ID:</strong> {selectedData.user_id}
                          </div>
                        </div>
                        {selectedData.data && (
                          <div>
                            {Object.entries(selectedData.data).map(([sectionTitle, sectionData]) => (
                              <div key={sectionTitle} style={{ marginBottom: '15px', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
                                <h5 style={{ marginBottom: '10px' }}>{sectionTitle.replace(/_/g, ' ')}</h5>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  {Object.entries(sectionData).map(([key, value]) => (
                                    <div key={key} style={{ marginBottom: '10px', marginRight: '10px', flex: '1 1 45%' }}>
                                      <strong>{key.replace(/_/g, ' ')}:</strong>
                                      {Array.isArray(value) ? (
                                        value.map((option, idx) => (
                                          <FormControlLabel
                                            key={idx}
                                            control={
                                              <Checkbox
                                                checked={true}
                                                disabled
                                              />
                                            }
                                            label={option}
                                          />
                                        ))
                                      ) : typeof value === 'boolean' ? (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={value}
                                              disabled
                                            />
                                          }
                                          label={key.replace(/_/g, ' ')}
                                        />
                                      ) : (
                                        <input
                                          type={typeof value === 'number' ? 'number' : 'text'}
                                          value={typeof value === 'object' ? JSON.stringify(value) : value}
                                          disabled
                                          style={{
                                            width: '100%',
                                            padding: '6px',
                                            borderRadius: '4px',
                                            border: '1px solid #ccc',
                                            marginTop: '5px'
                                          }}
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </DialogContent>
                </Dialog>
              </div>
            )}
          </>
        }
      />
      <Footer {...props} />
    </div>
  );
}
