import React, { useState, useEffect } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AuthApi from "../../Services/Authapi";
import swal from "sweetalert";
import AuthCheckComponent from "../../Layouts/Token";

const inivalue = {
  quli_id: 0,
  department: "",
};

const Departmentedit = (props) => {
  const classes = props;
  const [qulifi, setQulifi] = useState(inivalue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (props && props.authToken === false) {
      // props.history.push("/login");
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQulifi({ ...qulifi, [name]: value });
  };

  const validate = (values) => {
    let errors = {};

    if (!values.department) {
      errors.department = "Department name cannot be blank";
    }

    return errors;
  };

  const Departmentshowbyid = async () => {
    let quliId =
      props.history.location.pathname.split("/")[props.history.location.pathname.split("/").length - 1];
    let get = await AuthApi.Departmentshowbyid1(quliId);
    let quli_id = quliId;
    if (get && get.data) {
      setQulifi({
        quli_id: quli_id,
        department: get.data.department,
      });
    } else {
      console.error("Failed to fetch department data:", get);
    }
  };

  useEffect(() =>{ setTimeout(() => Departmentshowbyid(), 500)}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(qulifi));
    setIsSubmitting(true);
  };

  useEffect( () => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      Departmentupdate();
    }
  }, [formErrors]);

  const Departmentupdate = async (id) => {
    swal({
      title: "Loading",
      text: "Please wait...",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      width: "10%",
      content: {
        element: "img",
        attributes: {
          src: "https://www.boasnotas.com/img/loading2.gif",
          style: "width: 50px;" 
        }
      }
    });
    try {
      let status = await AuthApi.Departmentupdate1(qulifi, qulifi.quli_id);
      swal.close();
      if (status && status.status === true) {
        props.history.push("/Departmente");
      }
    } catch (error) {
      console.error("Error updating qulification:", error);
    }
  };

  return (
    <div>
      <AuthCheckComponent authToken={props.authToken} />
      <Header
        {...props}
        authUser={props.authUser}
        component={
          <div>
            <Card className={classes.root} style={{ marginBottom: "3%" }}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Department"
                  primaryPageLink="/Departmente"
                  isSecondaryPage={true}
                  secondaryPageName="Edit"
                />
              </CardContent>
            </Card>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="h2">Edit Department</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid container className={classes.root} spacing={5}>
                     
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="DepartmentName"
                            name="department"
                            label="Department"
                            variant="outlined"
                            error={formErrors.department && true}
                            value={qulifi.department || ""}
                            onChange={handleChange}
                          />
                          {formErrors.department && (
                            <span className="error">
                              {formErrors.department}
                            </span>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container className={classes.root} spacing={3}>
                        <Grid item xs={4} style={{ display: "flex" }}>
                          <Button
                            type="submit"
                            style={{ marginTop: "10px" }}
                            variant="contained"
                            color="primary"
                          >
                            Edit
                          </Button>
                          <Button
                            type="button"
                            style={{ marginTop: "10px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              props.history.push("/Departmente");
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </div> 
        }
      />
      <Footer />
    </div>
  );
};

export default Departmentedit;
