import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Header from '../../Layouts/Header';
import Footer from '../../Layouts/Footer';
import AuthApi from '../../Services/Authapi';
import TableComponent from '../../Layouts/TableComponent';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import swal from 'sweetalert';
import TextField from "@material-ui/core/TextField";
import AuthCheckComponent from '../../Layouts/Token';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import UserDetails from './UserDetails';

const styles = (theme) => ({
  root: {},
  searchField: {
    position: "absolute",
    right: "121px",
    marginTop: "21px"
  },
  paper: {
    position: 'absolute',
    right: '187px',
    marginTop: '62px',
    zIndex: 1,
    width: "210px",
    maxHeight: '200px',
    overflowY: 'auto',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
  },
});

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      count: 0,
      searchQuery: "",
      pageStatus: false,
      suggestions: [],
      filterEvents: [],
      tableTh: [
        { id: 'name', numeric: false, disablePadding: true, label: 'Employee ID', sortable: true },
        { id: 'photo', numeric: false, disablePadding: true, label: 'Profile Photo', sortable: true },
        { id: 'firstName', numeric: false, disablePadding: true, label: 'Name', sortable: true },
        { id: 'email', numeric: false, disablePadding: true, label: 'Email', sortable: true },
        { id: 'created_at', numeric: false, disablePadding: true, label: 'Created On', sortable: true },
        { id: 'actions', numeric: false, disablePadding: true, label: 'Actions', sortable: false }
      ],
    };
  }

  componentDidMount() {
    if (this.props && this.props.authToken === false) {
      // Uncomment if you want to redirect to login
      // this.props.history.push('/login');
    }
    this.userData();
  }

  userData = async () => {
    try {
      let users = await AuthApi.getData();
      if (users && users.status === true) {
        this.setState({
          users: users.data,
          count: users.data.length,
          filterEvents: users.data
        });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  removeUser = async (id) => {
    const confirm = await swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"]
    });
    if (confirm) {
      swal({
        title: "Loading",
        text: "Please wait...",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        width: "10%",
        content: {
          element: "img",
          attributes: {
            src: "https://www.boasnotas.com/img/loading2.gif",
            style: "width: 50px;"
          }
        }
      });
      try {
        let currentUser = await AuthApi.userDelete(id);
        swal.close();
        if (currentUser && currentUser.status === true) {
          this.userData();
          swal("User deleted successfully!", { icon: "success" });
        }
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  }

  statusRow = async (id, status) => {
    const confirm = await swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes"]
    });
    if (confirm) {
      swal({
        title: "Loading",
        text: "Please wait...",
        buttons: false,
        closeOnClickOutside: false,
        closeOnEsc: false,
        width: "10%",
        content: {
          element: "img",
          attributes: {
            src: "https://www.boasnotas.com/img/loading2.gif",
            style: "width: 50px;"
          }
        }
      });
      try {
        let statu = await AuthApi.UserStatus(id, status);
        swal.close();
        if (statu && statu.status === true) {
          this.userData();
        }
      } catch (error) {
        console.error('Error updating user status:', error);
      }
    }
  }

  handleChange = (e) => {
    const searchQuery = e.target.value;
    const suggestions = this.state.users.filter((item) => {
      return (
        item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.firstName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    this.setState({ searchQuery, suggestions });
  }

  handleSearch = () => {
    const filterEvents = this.state.users.filter((item) => {
      return (
        item.email.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
        item.firstName.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      );
    });
    this.setState({ filterEvents });
  }

  clearSearch = () => {
    this.setState({ searchQuery: "", suggestions: [] }, this.handleSearch);
  }

  handleSuggestionClick = (suggestion) => {
    this.setState({ searchQuery: suggestion.firstName, suggestions: [] }, this.handleSearch);
  }

  render() {
    const { classes } = this.props;
    const { filterEvents, users, searchQuery, suggestions, pageStatus, tableTh } = this.state;
    const permissions = (this.props.authUser && Array.isArray(this.props.authUser.userAccess)) ? this.props.authUser.userAccess : [];

    return (
      <Switch>
        <Route exact path="/users">
          <div>
            <AuthCheckComponent authToken={this.props.authToken} />
            <Header
              {...this.props}
              authUser={this.props.authUser}
              setAutUser={this.props.setAutUser}
              component={
                <div>
                  <Card className={classes.root} style={{ marginBottom: '3%' }}>
                    <CardContent>
                      <Breadcrumb
                        {...this.props}
                        primaryPageName="Users"
                        primaryPageLink="/users"
                        isSecondaryPage={false}
                        secondaryPageName="" />
                    </CardContent>
                  </Card>
                  <TextField
                    label="Search"
                    value={searchQuery}
                    onChange={this.handleChange}
                    className={classes.searchField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: searchQuery !== "",
                      style: {
                        color: '#1b6e95',
                        transform: searchQuery !== "" ? 'translate(14px, -6px) scale(0.75)' : 'translate(14px, 11px) scale(1)',
                        transition: 'transform 0.2s ease-in-out'
                      }
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: 'lightgray',
                        borderRadius: '4px',
                        height: "40px"
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchQuery && (
                            <ClearIcon onClick={this.clearSearch} style={{ cursor: 'pointer' }} />
                          )}
                          <SearchIcon onClick={this.handleSearch} style={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  {searchQuery && suggestions.length > 0 && (
                    <Paper className={classes.paper}>
                      {[...new Set(suggestions.map(suggestion => suggestion.firstName))].map((firstName, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => this.handleSuggestionClick({ firstName })}
                          style={{
                            padding: '10px 20px',
                            cursor: 'pointer'
                          }}
                        >
                          {firstName}
                        </MenuItem>
                      ))}
                    </Paper>
                  )}

                  <TableComponent
                    {...this.props}
                    tableTh={tableTh}
                    tableData={filterEvents}
                    tableCount={filterEvents.length}
                    tablePagestatus={pageStatus}
                    colNameToShow={['name', 'photo', 'firstName', 'email', 'created_at']}
                    openPopUp={false}
                    removeRow={this.removeUser}
                    statusRow={this.statusRow}
                    actionBtns={[permissions.includes("Users.Status") && ('status'), permissions.includes("Users.Edit") && ('update'), permissions.includes("Users.Delete") && ('delete')]}
                    modelName={'User'}
                    addRoute={'/users/add'}
                    updateRoute={'/users/edit'}
                    openPopUpUpdate={false}
                  />
                </div>
              }
            />
            <Footer {...this.props} />
          </div>
        </Route>

        <Route path="/users/:userId" render={({ match }) => {
          const userId = match.params.userId;
          const user = this.state.users.find(user => user.id === parseInt(userId));
          if (user && user.user_logged === 1) {
            return <UserDetails users={this.state.users} authToken={this.props.authToken} authUser={this.props.authUser} {...this.props} />;
          } else {
            swal({
              title: "User Not Active",
              text: "This user is currently not active.",
              icon: "warning",
              button: "OK"
            });
            return <Redirect to="/users" />;
          }
        }} />
      </Switch>
    );
  }
}

export default withStyles(styles)(User);
