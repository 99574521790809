/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AuthApi from "../../Services/Authapi";
import moment from "moment";
import swal from "sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";
import AuthCheckComponent from "../../Layouts/Token";

const inileaves = {
  leaveName: "",
  date: "",
  leave_id: 0,
};

const AddLeaves = (props) => {
  const classes = props;
  const [editValues, setEditValues] = useState(inileaves);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props && props.authToken === false) {
      // props.history.push("/login");
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });

    if (name === "date") {
      const selectedDate = moment(value, "YYYY-MM-DD");
      const dayOfWeek = selectedDate.day();

      if (dayOfWeek === 6 || dayOfWeek === 0) { // 6 = Saturday, 0 = Sunday
        swal({
          title: "Invalid Date",
          text: "Weekends are not allowed",
          icon: "error",
        });
      }
    }
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[a-zA-Z]/;
    const dateR =
      /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;

    if (!values.leaveName) {
      errors.leaveName = "Cannot be blank";
    } else if (!regex.test(values.leaveName)) {
      errors.leaveName = "Invalid leaveName format";
    }

    if (!values.date) {
      errors.date = "Cannot be blank";
    } else if (!dateR.test(values.date)) {
      errors.date = "Invalid date format";
    } else {
      const selectedDate = moment(values.date, "YYYY-MM-DD");
      const dayOfWeek = selectedDate.day();
      if (dayOfWeek === 6 || dayOfWeek === 0) {
        errors.date = "Weekends are not allowed";
      }
    }

    return errors;
  };

  const getLeaveData = async () => {
    let leaveId =
      props.history.location.pathname.split("/")[
      props.history.location.pathname.split("/").length - 1
      ];
    let leave = await AuthApi.singleLeave(leaveId);

    let leave_id = leaveId;
    let leaveName =
      leave.status === true &&
        leave.data &&
        leave.data.name &&
        (leave.data.name !== null || leave.data.name !== false)
        ? leave.data.name
        : null;
    let date =
      leave.status === true && leave.data && leave.data.date
        ? moment(leave.data.date, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;

    setEditValues({
      leaveName: leaveName,
      date: date,
      leave_id: leave_id,
    });
  };
  useEffect(() => { setTimeout(() => getLeaveData(), 500) }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(editValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
    } else if (errors.date) {
      swal({
        title: "Invalid Date",
        text: errors.date,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      editUser1();
    }
  }, [formErrors]);

  const editUser1 = async (id) => {
    swal({
      title: "Loading",
      text: "Please wait...",
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      width: "10%",
      content: {
        element: "img",
        attributes: {
          src: "https://www.boasnotas.com/img/loading2.gif",
          style: "width: 50px;",
        },
      },
    });
    try {
      let status = await AuthApi.updateLeave(editValues, editValues.leave_id);
      swal.close();
      if (status && status.status === true) {
        props.history.push("/leaves");
      } else {
        swal({
          title: "Error",
          text: status.message || "An unexpected error occurred.",
          icon: "error",
        });
      }
    } catch (error) {
      swal.close();
      console.error("Error updating leaves:", error);
      swal({
        title: "Error",
        text: "An unexpected error occurred while updating the leave.",
        icon: "error",
      });
    }
  };

  return (
    <div>
      <AuthCheckComponent authToken={props.authToken} />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <CircularProgress />
          </div>
        </div>
      )}
      <Header
        {...props}
        authUser={props.authUser}
        component={
          <div>
            <Card className={classes.root} style={{ marginBottom: "3%" }}>
              <CardContent>
                <Breadcrumb
                  {...props}
                  primaryPageName="Holiday"
                  primaryPageLink="/leaves"
                  isSecondaryPage={true}
                  secondaryPageName="Edit Holiday"
                />
              </CardContent>
            </Card>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="h2">Edit Holiday</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <Grid container className={classes.root} spacing={5}>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="leaveName"
                            name="leaveName"
                            label="Holiday Name"
                            variant="outlined"
                            inputProps={{ maxLength: 100 }}
                            error={formErrors.leaveName && true}
                            value={
                              editValues && editValues.leaveName !== null
                                ? editValues.leaveName
                                : null
                            }
                            focused={
                              editValues && editValues.leaveName !== null
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            className={formErrors.leaveName && "input-error"}
                          />
                          {formErrors.leaveName && (
                            <span className="error">
                              {formErrors.leaveName}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="date"
                            variant="outlined"
                            label="Date"
                            name="date"
                            type="date"
                            formate="yyyy-mm-dd"
                            error={formErrors.date && true}
                            value={
                              editValues && editValues.date !== null
                                ? editValues.date
                                : ""
                            }
                            focused={
                              editValues && editValues.date !== null
                                ? true
                                : false
                            }
                            onChange={handleChange}
                            className={formErrors.date && "input-error"}
                            // className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: moment().add(1, 'days').format("YYYY-MM-DD"),
                            }}
                          />
                          {formErrors.date && (
                            <span className="error">
                              {formErrors.date}
                            </span>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container className={classes.root} spacing={3}>
                        <Grid item xs={4} style={{ display: "flex" }}>
                          <Button
                            type="submit"
                            style={{ marginTop: "10px" }}
                            variant="contained"
                            color="primary"
                          >
                            Edit{" "}
                          </Button>
                          <Button
                            type="button"
                            style={{ marginTop: "10px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              props.history.push("/leaves");
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </div>
        }
      />
      <Footer />
    </div>
  );
};

export default AddLeaves;