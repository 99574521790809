import React, { useState, useEffect } from 'react';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import { Card, CardContent, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Typography } from '@material-ui/core';
import Breadcrumb from '../../Layouts/Breadcrumb';
import Authapi from '../../Services/Authapi';
import swal from 'sweetalert';
import AuthCheckComponent from '../../Layouts/Token';

export default function Reviewformedit(props) {
    const [data, setdata] = useState({
        form_name: '',
        form_details: [],
        form_content: []
    });
    const classes = props;

    useEffect(() => {
        Reviewformlist();
    }, []);

    const Reviewformlist = async () => {
        let Id = props.history.location.pathname.split('/').pop();
        try {
            let role = await Authapi.ReviewformlistgetformbyID(Id);
            setdata({
                form_name: role.data.form_name || '',
                form_details: role.data.form_details || [],
                form_content: role.data.form_content || []
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (index, field, value) => {
        const newDetails = [...data.form_details];
        newDetails[index][field] = value;
        setdata(prevState => ({
            ...prevState,
            form_details: newDetails
        }));
    };

    const handleContentChange = (index, field, value) => {
        const newContent = [...data.form_content];
        newContent[index][field] = value;
        setdata(prevState => ({
            ...prevState,
            form_content: newContent
        }));
    };

    const handleOptionChange = (inputIndex, optionIndex, value) => {
        const newDetails = [...data.form_details];
        if (newDetails[inputIndex] && newDetails[inputIndex].options) {
            newDetails[inputIndex].options[optionIndex] = value;
            setdata(prevState => ({
                ...prevState,
                form_details: newDetails
            }));
        }
    };

    const handleContentOptionChange = (contentIndex, optionIndex, value) => {
        const newContent = [...data.form_content];
        if (newContent[contentIndex] && newContent[contentIndex].options) {
            newContent[contentIndex].options[optionIndex] = value;
            setdata(prevState => ({
                ...prevState,
                form_content: newContent
            }));
        }
    };

    const addOption = (contentIndex) => {
        setdata(prevState => {
            const newContent = [...prevState.form_content];
            if (!newContent[contentIndex].options) {
                newContent[contentIndex].options = [];
            }
            newContent[contentIndex].options.push('');
            return { ...prevState, form_content: newContent };
        });
    };

    const removeOption = (contentIndex, optionIndex) => {
        setdata(prevState => {
            const newContent = [...prevState.form_content];
            if (newContent[contentIndex] && newContent[contentIndex].options) {
                newContent[contentIndex].options.splice(optionIndex, 1);
            }
            return { ...prevState, form_content: newContent };
        });
    };

    const addMainInputField = () => {
        setdata(prevState => ({
            ...prevState,
            form_details: [...prevState.form_details, { type: '', label: '', options: [] }]
        }));
    };

    const removeMainInputField = (inputIndex) => {
        const newDetails = [...data.form_details];
        newDetails.splice(inputIndex, 1);
        setdata(prevState => ({
            ...prevState,
            form_details: newDetails
        }));
    };

    const addContentField = () => {
        setdata(prevState => ({
            ...prevState,
            form_content: [...prevState.form_content, { title: '', label: '', type: '', options: [] }]
        }));
    };

    const removeContentField = (contentIndex) => {
        const newContent = [...data.form_content];
        newContent.splice(contentIndex, 1);
        setdata(prevState => ({
            ...prevState,
            form_content: newContent
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await createupdate();
    };





    const createupdate = async () => {
        const id = props.history.location.pathname.split('/').pop();
        swal({
            title: "Loading",
            text: "Please wait...",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
            width: "10%",
            content: {
                element: "img",
                attributes: {
                    src: "https://www.boasnotas.com/img/loading2.gif",
                    style: "width: 50px;"
                }
            }
        });

        try {
            const transformedInputs = data.form_details.map(input => ({
                label: input.label,
                type: input.type,
                options: (input.type === 'radio' || input.type === 'checkbox') ? input.options : []
            }));

            const transformedPostTitles = data.form_content.map(content => ({
                title: content.title,
                label: content.label,
                type: content.type,
                options: (content.type === 'radio' || content.type === 'checkbox') ? content.options : []
            }));

            const postData = {
                form_name: data.form_name,
                form_details: transformedInputs,
                form_content: transformedPostTitles
            };

            let create = await Authapi.Reviewformupdate(postData, id);
            swal.close();
            if (create && create.status === true) {
                props.history.push("/Reviewformlist");
            } else {
                swal({
                    title: "OOPS!",
                    icon: "fail",
                    message: "Something went wrong, Please try later!",
                });
            }
        } catch (error) {
            console.error("Error updating role:", error);
        }
    };

    return (
        <div>
            <AuthCheckComponent authToken={props.authToken} />
            <Header
                {...props}
                authUser={props.authUser}
                setAuthUser={props.setAuthUser}
                component={
                    <div>
                        <Card className={classes.root} style={{ marginBottom: '3%' }}>
                            <CardContent>
                                <Breadcrumb {...props}
                                    primaryPageName="Edit Form"
                                    primaryPageLink="/dashboard"
                                    isSecondaryPage={false}
                                    secondaryPageName="" />
                            </CardContent>
                        </Card>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="h2" style={{ paddingTop: 0, paddingLeft: 0, paddingBottom: 0 }}>Edit Form</Typography>
                                        <form className={classes.form} onSubmit={handleSubmit}>
                                            <Grid container className={classes.root} spacing={5}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Form Name"
                                                        value={data.form_name}
                                                        onChange={(e) => setdata({ ...data, form_name: e.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {data.form_details.map((input, inputIndex) => (
                                                <Grid container spacing={2} key={inputIndex}>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            fullWidth
                                                            label={`Input Label ${inputIndex + 1}`}
                                                            value={input.label}
                                                            onChange={(e) => handleInputChange(inputIndex, 'label', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>Input Type</InputLabel>
                                                            <Select
                                                                value={input.type}
                                                                onChange={(e) => handleInputChange(inputIndex, 'type', e.target.value)}
                                                            >
                                                                <MenuItem value="text">Text</MenuItem>
                                                                <MenuItem value="date">Date</MenuItem>
                                                                <MenuItem value="email">Email</MenuItem>
                                                                <MenuItem value="number">Number</MenuItem>
                                                                <MenuItem value="textarea">Textarea</MenuItem>
                                                                <MenuItem value="checkbox">Checkbox</MenuItem>
                                                                <MenuItem value="radio">Radio</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button
                                                            onClick={() => removeMainInputField(inputIndex)}
                                                            variant="contained"
                                                            style={{
                                                                color: "#fff",
                                                                backgroundColor: "#d12c2c",
                                                                marginTop: "10px"
                                                            }}>
                                                            Remove
                                                        </Button>
                                                    </Grid>
                                                    {(input.type === 'radio' || input.type === 'checkbox') && (
                                                        <Grid item xs={12}>
                                                            {input.options && input.options.map((option, optionIndex) => (
                                                                <Grid container spacing={2} key={optionIndex}>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label={`Option ${optionIndex + 1}`}
                                                                            value={option}
                                                                            onChange={(e) => handleOptionChange(inputIndex, optionIndex, e.target.value)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => removeOption(inputIndex, optionIndex)}
                                                                            variant="contained"
                                                                            style={{
                                                                                color: "#fff",
                                                                                backgroundColor: "#d12c2c",
                                                                                marginTop: "10px"
                                                                            }}>
                                                                            Remove
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                            <Button
                                                                onClick={() => addOption(inputIndex)}
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ marginTop: "10px" }}>
                                                                Add Option
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            <Button
                                                onClick={addMainInputField}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginTop: "10px" }}>
                                                Add Input
                                            </Button>
                                            {data.form_content.map((content, contentIndex) => (
                                                <Grid container spacing={2} key={contentIndex}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            label={`Form Title ${contentIndex + 1}`}
                                                            value={content.title}
                                                            onChange={(e) => handleContentChange(contentIndex, 'title', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            fullWidth
                                                            label={`Content Label ${contentIndex + 1}`}
                                                            value={content.label}
                                                            onChange={(e) => handleContentChange(contentIndex, 'label', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <InputLabel>Input Type</InputLabel>
                                                            <Select
                                                                value={content.type || ''}
                                                                onChange={(e) => handleContentChange(contentIndex, 'type', e.target.value)}
                                                            >
                                                                <MenuItem value="text">Text</MenuItem>
                                                                <MenuItem value="date">Date</MenuItem>
                                                                <MenuItem value="email">Email</MenuItem>
                                                                <MenuItem value="number">Number</MenuItem>
                                                                <MenuItem value="textarea">Textarea</MenuItem>
                                                                <MenuItem value="checkbox">Checkbox</MenuItem>
                                                                <MenuItem value="radio">Radio</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button
                                                            onClick={() => removeContentField(contentIndex)}
                                                            variant="contained"
                                                            style={{
                                                                color: "#fff",
                                                                backgroundColor: "#d12c2c",
                                                                marginTop: "10px"
                                                            }}>
                                                            Remove
                                                        </Button>
                                                    </Grid>
                                                    {(content.type === 'radio' || content.type === 'checkbox') && (
                                                        <Grid item xs={12}>
                                                            {content.options && content.options.map((option, optionIndex) => (
                                                                <Grid container spacing={2} key={optionIndex}>
                                                                    <Grid item xs={8}>
                                                                        <TextField
                                                                            fullWidth
                                                                            label={`Option ${optionIndex + 1}`}
                                                                            value={option}
                                                                            onChange={(e) => handleContentOptionChange(contentIndex, optionIndex, e.target.value)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <Button
                                                                            onClick={() => removeOption(contentIndex, optionIndex)}
                                                                            variant="contained"
                                                                            style={{
                                                                                color: "#fff",
                                                                                backgroundColor: "#d12c2c",
                                                                                marginTop: "10px"
                                                                            }}>
                                                                            Remove
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                            <Button
                                                                onClick={() => addOption(contentIndex)}
                                                                variant="contained"
                                                                color="primary"
                                                                style={{ marginTop: "10px" }}>
                                                                Add Option
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            <Button
                                                onClick={addContentField}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginTop: "10px" }}>
                                                Add Content
                                            </Button>

                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={4}>
                                                    <Button type="submit" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} variant="contained" color="primary">Edit</Button>
                                                    <Button type="button" style={{ marginTop: "10px", float: 'left', marginBottom: "15px" }} onClick={(e) => { e.preventDefault(); props.history.push('/Reviewformlist') }}>Cancel</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                }
            />
            <Footer {...props} />
        </div>
    )
}
